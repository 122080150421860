var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mx-0 d-flex flex-column align-center",
      staticStyle: { "background-color": "#f1f2f1" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-grow-1 border-left border-right",
          class: { "flex-column": _vm.$vuetify.breakpoint.xsOnly },
          style: {
            height: _vm.$vuetify.breakpoint.xsOnly
              ? undefined
              : `calc(100vh - 56px)`,
            width: `100%`,
          },
        },
        [
          !_vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "section",
                {
                  staticClass: "white overflow-y-auto border-right",
                  staticStyle: { height: "calc(100vh - 56px)", width: "300px" },
                },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "flex-grow-0",
                      staticStyle: { width: "300px" },
                      attrs: { flat: "", multiple: "", accordion: "" },
                      model: {
                        value: _vm.openPanels,
                        callback: function ($$v) {
                          _vm.openPanels = $$v
                        },
                        expression: "openPanels",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { attrs: { value: 0 } },
                        [
                          _c("v-expansion-panel-header", [
                            _c("h2", [_vm._v("Configuration")]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "border-top" },
                            [
                              _c("ReportConfigurationForm", {
                                on: {
                                  input: function ($event) {
                                    return _vm.$emit("input", $event)
                                  },
                                },
                                model: {
                                  value: _vm.reportDefinition,
                                  callback: function ($$v) {
                                    _vm.reportDefinition = $$v
                                  },
                                  expression: "reportDefinition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "border-top border-bottom",
                          attrs: { value: 1 },
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "header" },
                            [_vm._v(" Smart Fields ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "border-top" },
                            [
                              _c("ReportSmartFieldsForm", {
                                attrs: {
                                  gisDataFields: _vm.gisDataFields,
                                  mapServiceId:
                                    _vm.reportDefinition.map_service_id,
                                  templatePart: _vm.templatePart,
                                },
                                on: {
                                  "insert-placeholder": _vm.insertPlaceholder,
                                  "insert-table-string": _vm.insertTableString,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "section",
            {
              staticClass:
                "d-flex justify-center pa-0 overflow-hidden flex-grow-1",
              staticStyle: { height: "100%" },
            },
            [
              _c("validation-provider", {
                attrs: { name: "Form Template Editor" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors, valid }) {
                      return [
                        _c("ReportTemplateEditor", {
                          ref: "templateEditor",
                          attrs: {
                            errors: errors,
                            valid: valid,
                            disabled: _vm.disabled,
                            reportDefinition: _vm.reportDefinition,
                            gisDataFields: _vm.gisDataFields,
                            selectedGisDataFieldName:
                              _vm.selectedGisDataFieldName,
                            selectedTableHtmlString:
                              _vm.selectedTableHtmlString,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", _vm.reportDefinition)
                            },
                            "input:header": _vm.onHeaderInput,
                            "input:footer": _vm.onFooterInput,
                            "input:docSettings": _vm.onInputDocSettings,
                            "template-part-changed": function ($event) {
                              _vm.templatePart = $event
                            },
                          },
                          model: {
                            value: _vm.reportDefinition.html_merge_definition,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.reportDefinition,
                                "html_merge_definition",
                                $$v
                              )
                            },
                            expression:
                              "reportDefinition.html_merge_definition",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }