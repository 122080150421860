<template>
  <v-dialog :value="showAddContactDialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="primary">
        <h3 class="font-weight-regular">Add Contact</h3>
        <v-spacer />
        <v-btn icon @click="$emit('add-contacts-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <validation-observer
        slim
        ref="contactForm"
        v-slot="{ valid: isFormValid }"
      >
        <form @submit.prevent="addContact">
          <v-card-text class="pt-3">
            <validation-provider
              :rules="{ required: true, email: true, emailNotExist: contacts }"
              v-slot="{ errors, valid }"
              name="Email"
            >
              <v-text-field
                v-model="contact.email"
                class="flex-grow-1"
                label="Email *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="First Name"
            >
              <v-text-field
                v-model="contact.f_name"
                class="flex-grow-1"
                label="First Name *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="Last Name"
            >
              <v-text-field
                v-model="contact.l_name"
                class="flex-grow-1"
                label="Last Name *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Title">
              <v-text-field
                v-model="contact.title"
                class="flex-grow-1"
                label="Title"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Company">
              <v-text-field
                v-model="contact.company"
                class="flex-grow-1"
                label="Company"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{
                regex: /(\d{3}-?\s?\d{3}-?\s?\d{4}\s?)?(ext. \d{4})?/,
              }"
              v-slot="{ errors, valid }"
              name="Phone Number"
            >
              <v-text-field
                v-model="contact.phone_no"
                class="flex-grow-1"
                label="Phone Number"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-5">
            <v-btn :disabled="!isFormValid" type="submit" color="primary">
              Create Site Contact
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPlus, mdiMagnify } from "@mdi/js";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddContactDialog",
  props: {
    showAddContactDialog: Boolean,
    contacts: Array,
  },
  data() {
    return {
      mdiClose,
      mdiPlus,
      mdiMagnify,
      contact: {
        email: "",
        name: "",
        title: "",
        company: "",
        phone_no: "",
      },
    };
  },
  methods: {
    async addContact() {
      const success = await this.$refs.contactForm.validate();
      if (!success) {
        return;
      }
      const {
        email,
        f_name: fName,
        l_name: lName,
        title,
        company,
        phone_no: phoneNumber,
      } = this.contact;
      await axios.post(`${APIURL}/contacts`, {
        email,
        f_name: fName,
        l_name: lName,
        title,
        company,
        phone_no: phoneNumber,
      });
      this.$emit("contact-added");
    },
  },
};
</script>
