<template>
  <div class="border-left">
    <section v-show="editingHtml" class="template-editor">
      <div
        class="d-flex flex-wrap justify-space-between align-center background"
      >
        <section class="d-flex align-center justify-space-between px-3">
          <v-btn text @click="editingHtml = false" class="px-0 mx-0">
            <v-icon size="20">{{ mdiChevronLeft }}</v-icon>
            Visual Doc Builder
          </v-btn>
        </section>
      </div>
      <section class="d-flex">
        <TemplateCodeEditor
          :value="templateCodeEditorValue"
          :contentHeight="contentHeight"
          @input="onEditorInput"
          class="overflow-y-auto flex-grow-1"
          style="height: auto"
        />
      </section>
    </section>

    <section
      v-show="!editingHtml"
      class="template-editor"
      :style="{ width: `${contentWidth}px`, background: '#6f6f6f' }"
    >
      <section
        class="background px-2"
        ref="topBar"
        :style="{ background: '#fafafa' }"
      >
        <div v-if="editor" class="d-flex flex-wrap align-center flex-grow-0">
          <div style="width: 180px">
            <v-select
              autocomplete="off"
              @change="onFontFamilyChange"
              :items="fontFamilyOptions"
              item-text="label"
              item-value="value"
              :value="fontFamilyValue"
              class="mr-2"
              :disabled="disabled"
            >
              <template #item="{ item: { label, value } }">
                <div
                  class="py-0 my-0"
                  :style="{
                    'font-family': value,
                  }"
                >
                  {{ label }}
                </div>
              </template>

              <template #selection="{ item: { label, value } }">
                <div
                  class="py-0 my-0"
                  :style="{
                    'font-family': value,
                  }"
                >
                  {{ label }}
                </div>
              </template>
            </v-select>
          </div>

          <div style="flex-basis: 50px">
            <v-select
              autocomplete="off"
              @change="onFontSizeChange"
              :items="fontSizeOptions"
              :value="fontSizeValue"
              item-text="label"
              item-value="value"
              :disabled="disabled"
            ></v-select>
          </div>

          <v-btn
            icon
            @click="editor.chain().focus().undo().run()"
            :disabled="!editor.can().undo() || disabled"
          >
            <v-icon size="20">
              {{ mdiUndo }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            @click="editor.chain().focus().redo().run()"
            :disabled="!editor.can().redo() || disabled"
          >
            <v-icon size="20">
              {{ mdiRedo }}
            </v-icon>
          </v-btn>

          <v-menu
            v-model="showColorPicker"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-2 d-inline-block">
                <v-btn
                  icon
                  class="main-btn"
                  @click="editor.chain().focus().setColor(colorSelection).run()"
                  :disabled="disabled"
                >
                  <v-icon size="20" :color="colorSelection">
                    {{ mdiFormatColorText }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="actions-btn"
                  :disabled="disabled"
                >
                  <v-icon size="12">{{ mdiChevronDown }}</v-icon>
                </v-btn>
              </div>
            </template>

            <ColorForm
              @cancel="showColorPicker = false"
              @color-saved="onTextColorSaved"
            />
          </v-menu>

          <v-btn
            icon
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatBold }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatItalic }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'is-active': editor.isActive('underline') }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatUnderline }}</v-icon>
          </v-btn>
          <v-menu
            v-model="showLinkMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :class="{ 'is-active': editor.isActive('link') }"
                @click="getLink()"
                :disabled="disabled"
              >
                <v-icon size="20">{{ mdiLink }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="pt-2 pb-3 px-4">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Input URL"
                >
                  <v-text-field
                    v-model="inputURL"
                    label="Input URL"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="name"
                    class="pt-1"
                    :disabled="disabled"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text @click="showLinkMenu = false" :disabled="disabled">
                  Cancel
                </v-btn>
                <v-btn
                  dark
                  color="primary"
                  @click="
                    showLinkMenu = false;
                    setLink(inputURL);
                  "
                  :disabled="disabled"
                >
                  Set URL
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn
            v-if="editor.isActive('link')"
            icon
            @click="editor.chain().focus().unsetLink().run()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiLinkOff }}</v-icon>
          </v-btn>
          <v-icon size="20">{{ mdiPowerOn }}</v-icon>
          <v-btn
            icon
            @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatAlignLeft }}</v-icon></v-btn
          >
          <v-btn
            icon
            @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{
              'is-active': editor.isActive({ textAlign: 'center' }),
            }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatAlignCenter }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{
              'is-active': editor.isActive({ textAlign: 'right' }),
            }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatAlignRight }}</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{
              'is-active': editor.isActive({ textAlign: 'justify' }),
            }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatAlignJustify }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatListBulleted }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiFormatListNumbered }}</v-icon>
          </v-btn>
          <v-icon size="20">{{ mdiPowerOn }}</v-icon>
          <v-btn icon @click="insertPageBreak" :disabled="disabled">
            <v-icon size="20">{{ mdiFormatPageBreak }}</v-icon>
          </v-btn>
          <v-menu
            v-model="showImageLinkMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :disabled="disabled">
                <v-icon size="20">{{ mdiImage }}</v-icon>
              </v-btn>
            </template>
            <ImageForm
              @cancel="showImageLinkMenu = false"
              @add-image="addImage"
            />
          </v-menu>
          <v-menu
            v-if="!editor.isActive('link')"
            v-model="showTableMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :disabled="disabled">
                <v-icon size="20">{{ mdiTable }}</v-icon>
              </v-btn>
            </template>

            <TableMenu
              v-if="showTableMenu"
              @cancel="showTableMenu = false"
              @insert-table="onInsertTable"
            />
          </v-menu>
          <v-menu v-model="showLogicMenu" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-inline-block">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="disabled">
                  <v-icon size="20">{{ mdiLightbulbOnOutline }}</v-icon>
                </v-btn>
              </div>
            </template>

            <LogicMenu @logic-choice-selected="onLogicChoiceSelected" />
          </v-menu>

          <v-icon size="20" v-if="showTableTypeButtons">
            {{ mdiPowerOn }}
          </v-icon>

          <v-btn
            icon
            @click="editor.chain().focus().addColumnBefore().run()"
            v-if="editor.can().addColumnBefore()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableColumnPlusBefore }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().addColumnAfter().run()"
            v-if="editor.can().addColumnAfter()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableColumnPlusAfter }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().deleteColumn().run()"
            v-if="editor.can().deleteColumn()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableColumnRemove }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().addRowBefore().run()"
            v-if="editor.can().addRowBefore()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableRowPlusBefore }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().addRowAfter().run()"
            v-if="editor.can().addRowAfter()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableRowPlusAfter }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().deleteRow().run()"
            v-if="editor.can().deleteRow()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableRowRemove }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().deleteTable().run()"
            v-if="editor.can().deleteTable()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableRemove }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().mergeCells().run()"
            v-if="editor.can().mergeCells()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableMergeCells }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editor.chain().focus().splitCell().run()"
            v-if="editor.can().splitCell()"
            :disabled="disabled"
          >
            <v-icon size="20">{{ mdiTableSplitCell }}</v-icon>
          </v-btn>

          <v-icon size="20" v-if="showTableTypeButtons">
            {{ mdiPowerOn }}
          </v-icon>
          <v-menu offset-y v-if="showTableTypeButtons">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-inline-block">
                <v-btn
                  icon
                  class="main-btn"
                  @click="onTableBorderSaved(borderStyle)"
                  :disabled="disabled"
                >
                  <img
                    class="icon"
                    :src="
                      require(`@/assets/img/borders/border-${borderStyle}.svg`)
                    "
                  />
                </v-btn>
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="actions-btn"
                  :disabled="disabled"
                >
                  <v-icon size="12">{{ mdiChevronDown }}</v-icon>
                </v-btn>
              </div>
            </template>

            <TableBorderMenu @border-style-selected="onTableBorderSaved" />
          </v-menu>
          <v-menu
            v-model="showCellColorPicker"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            v-if="showTableTypeButtons"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-inline-block">
                <v-btn
                  icon
                  class="main-btn"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setCellAttribute('backgroundColor', cellColorSelection)
                      .run()
                  "
                  :disabled="disabled"
                >
                  <v-icon size="20" :color="cellColorSelection">
                    {{ mdiFormatColorFill }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="actions-btn"
                  :disabled="disabled"
                >
                  <v-icon size="12">{{ mdiChevronDown }}</v-icon>
                </v-btn>
              </div>
            </template>

            <ColorForm
              @cancel="showCellColorPicker = false"
              @color-saved="onCellBackgroundColorSaved"
            />
          </v-menu>
          <v-menu
            v-if="showTableTypeButtons"
            v-model="showCellMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :disabled="disabled">
                <v-icon size="20">{{ mdiCog }}</v-icon>
              </v-btn>
            </template>

            <CellPaddingForm
              @cancel="showCellMenu = false"
              @saved="onCellPaddingSaved"
            />
          </v-menu>
        </div>
      </section>

      <div
        class="overflow-auto pa-3"
        :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }"
      >
        <div
          :style="{
            height: `${pageSettings.pageHeight}in`,
            width: `${pageSettings.pageWidth}in`,
            'min-height': `${pageSettings.pageHeight}in`,
            'min-width': `${pageSettings.pageWidth}in`,
            margin: '0 auto',
          }"
        >
          <section @click="templatePart = TEMPLATE_PART.HEADER" class="white">
            <div class="d-flex justify-end">
              <v-btn
                icon
                @click="openCodeEditor(TEMPLATE_PART.HEADER)"
                :disabled="disabled"
              >
                <v-icon>
                  {{ mdiCodeTags }}
                </v-icon>
              </v-btn>
            </div>

            <div class="d-flex mt-n7 pa-0 ma-0">
              <div
                :style="{
                  width: `${pageSettings.marginLeft}in`,
                  'border-right': '2px solid #ddffff',
                  'border-bottom': '2px solid #ddffff',
                }"
              ></div>
              <editor-content
                class="overflow-y-auto flex-grow-1"
                :editor="headerEditor"
                :style="{
                  width: `${
                    pageSettings.pageWidth -
                    +pageSettings.marginLeft -
                    +pageSettings.marginRight
                  }in`,
                  height: `${pageSettings.marginTop}in`,
                }"
              />
              <div
                :style="{
                  width: `${pageSettings.marginRight}in`,
                  'border-left': '2px solid #ddffff',
                  'border-bottom': '2px solid #ddffff',
                }"
              ></div>
            </div>
          </section>

          <section
            @click="templatePart = TEMPLATE_PART.BODY"
            class="white flex-grow-1 d-flex flex-column"
          >
            <div class="d-flex justify-end">
              <v-btn
                @click="openCodeEditor(TEMPLATE_PART.BODY)"
                icon
                :disabled="disabled"
              >
                <v-icon>
                  {{ mdiCodeTags }}
                </v-icon>
              </v-btn>
            </div>

            <div
              class="d-flex pa-0"
              :style="{
                'margin-top': '-29px',
                'margin-bottom': '-9px',
              }"
            >
              <div
                :style="{
                  width: `${pageSettings.marginLeft}in`,
                  'min-height': `${
                    +pageSettings.pageHeight -
                    +pageSettings.marginTop -
                    +pageSettings.marginBottom
                  }in`,
                  'border-right': '2px solid #ddffff',
                }"
              ></div>

              <editor-content
                class="overflow-y-auto flex-grow-1"
                :editor="bodyEditor"
                :style="{
                  width: `${
                    pageSettings.pageWidth -
                    +pageSettings.marginLeft -
                    +pageSettings.marginRight
                  }in`,
                  'min-height': `${
                    +pageSettings.pageHeight -
                    +pageSettings.marginTop -
                    +pageSettings.marginBottom
                  }in`,
                  'border-top': '2px solid #ddffff',
                  'border-bottom': '2px solid #ddffff',
                }"
              />
              <div
                :style="{
                  width: `${pageSettings.marginRight}in`,
                  'min-height': `${
                    +pageSettings.pageHeight -
                    +pageSettings.marginTop -
                    +pageSettings.marginBottom
                  }in`,
                  'border-left': '2px solid #ddffff',
                }"
              ></div>
            </div>
          </section>

          <section @click="templatePart = TEMPLATE_PART.FOOTER" class="white">
            <div class="d-flex justify-end">
              <v-btn
                @click="openCodeEditor(TEMPLATE_PART.FOOTER)"
                icon
                :disabled="disabled"
              >
                <v-icon>
                  {{ mdiCodeTags }}
                </v-icon>
              </v-btn>
            </div>

            <div class="d-flex mt-n5 pa-0 ma-0">
              <div
                :style="{
                  width: `${pageSettings.marginLeft}in`,
                  'border-right': '2px solid #ddffff',
                  'border-top': '2px solid #ddffff',
                }"
              ></div>
              <editor-content
                class="overflow-y-auto flex-grow-1"
                :editor="footerEditor"
                :style="{
                  width: `${
                    pageSettings.pageWidth -
                    +pageSettings.marginLeft -
                    +pageSettings.marginRight
                  }in`,
                  height: `${pageSettings.marginBottom}in`,
                }"
              />
              <div
                :style="{
                  width: `${pageSettings.marginRight}in`,
                  'border-left': '2px solid #ddffff',
                  'border-top': '2px solid #ddffff',
                }"
              ></div>
            </div>
          </section>
        </div>
      </div>

      <p class="caption">{{ placeholder }}</p>
      <p class="caption red--text" v-if="Array.isArray(errors)">
        {{ errors[0] }}
      </p>

      <InsertMultipleImagesDialog
        :showInsertMultipleImagesDialog="showInsertMultipleImagesDialog"
        v-if="showInsertMultipleImagesDialog"
        @close-insert-images-dialog="showInsertMultipleImagesDialog = false"
        @insert-multiple-image="insertMultipleImages"
      />

      <PhotoSizeDialog
        :showPhotoSizeDialog="showPhotoSizeDialog"
        :selectedFormField="selectedFormField"
        v-if="showPhotoSizeDialog"
        @close-photo-size-dialog="showPhotoSizeDialog = false"
        @insert-image="onInsertImage"
      />

      <ReportStatementDialog
        v-if="showStatementDialog"
        :showStatementDialog="showStatementDialog"
        :selectedLogicStatement="selectedLogicStatement"
        :gisDataFields="gisDataFields"
        @close-statement-dialog="showStatementDialog = false"
        @statement-saved="onStatementSaved"
      />
    </section>
  </div>
</template>

<script>
import {
  mdiPowerOn,
  mdiChevronDown,
  mdiFormatColorText,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiLink,
  mdiLinkOff,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatAlignCenter,
  mdiFormatAlignJustify,
  mdiTable,
  mdiTableColumnPlusBefore,
  mdiTableColumnPlusAfter,
  mdiTableColumnRemove,
  mdiTableRowPlusBefore,
  mdiTableRowPlusAfter,
  mdiTableRowRemove,
  mdiTableRemove,
  mdiTableMergeCells,
  mdiTableSplitCell,
  mdiTableCog,
  mdiUndo,
  mdiRedo,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiImage,
  mdiFormatPageBreak,
  mdiCodeTags,
  mdiFileDocumentEdit,
  mdiPlus,
  mdiFormatColorFill,
  mdiBorderAllVariant,
  mdiBorderNoneVariant,
  mdiBorderTopVariant,
  mdiBorderBottomVariant,
  mdiBorderLeftVariant,
  mdiBorderRightVariant,
  mdiCog,
  mdiDotsVertical,
  mdiLightbulbOnOutline,
  mdiChevronLeft,
} from "@mdi/js";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Document from "@tiptap/extension-document";
import ResizableImage from "@/helpers/ResizableImage";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import FontSize from "@/helpers/FontSize";
import ImageForm from "@/components/shared/template-editor/ImageForm";
import LogicMenu from "@/components/shared/template-editor/LogicMenu";
import ReportStatementDialog from "@/components/shared/report-template-editor/ReportStatementDialog";
import CellPaddingForm from "@/components/shared/template-editor/CellPaddingForm";
import ColorForm from "@/components/shared/template-editor/ColorForm";
import TableBorderMenu from "@/components/shared/template-editor/TableBorderMenu";
import InsertMultipleImagesDialog from "@/components/shared/template-editor/InsertMultipleImagesDialog";
import TableMenu from "@/components/shared/template-editor/TableMenu";
import TemplateCodeEditor from "@/components/shared/template-editor/TemplateCodeEditor";
import StatementNode from "@/helpers/StatementNode";
import ParamPlaceholder from "@/helpers/ParamPlaceholder";
import ImagePlaceholder from "@/helpers/ImagePlaceholder";
import SignaturePlaceholder from "@/helpers/SignaturePlaceholder";
import MissingParamPlaceholder from "@/helpers/MissingParamPlaceholder";
import MissingImagePlaceholder from "@/helpers/MissingImagePlaceholder";
import MissingSignaturePlaceholder from "@/helpers/MissingSignaturePlaceholder";
import PageBreak from "@/helpers/PageBreak";
import CustomTableCell from "@/helpers/CustomTableCell";
import { LOGIC } from "@/constants/logicChoices";
import BORDER_STYLE from "@/constants/borderStyle";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import PhotoSizeDialog from "@/components/shared/template-editor/PhotoSizeDialog";
import { mapMutations } from "vuex";
import CustomParagraph from "@/helpers/CustomParagraph";
import CustomTable from "@/helpers/CustomTable";
import CustomLink from "@/helpers/CustomLink";
import Div from "@/helpers/Div";
import Section from "@/helpers/Section";
import ParamPlaceholderBlock from "@/helpers/ParamPlaceholderBlock";
import MissingParamPlaceholderBlock from "@/helpers/MissingParamPlaceholderBlock";
import { TEMPLATE_PART } from "@/constants/templateEditor";

const fontFamilyOptions = [
  {
    value: "Cedarville Cursive",
    label: "Cedarville Cursive",
  },
  {
    value: "Comic Neue",
    label: "Comic Neue",
  },
  {
    value: "Open Sans",
    label: "Open Sans",
  },
  {
    value: "PT Serif",
    label: "PT Serif",
  },
  {
    value: "roboto",
    label: "Roboto",
  },
  {
    value: "Space Mono",
    label: "Space Mono",
  },
];

const fontSizeOptions = [
  {
    value: "8px",
    label: "8",
  },
  {
    value: "9px",
    label: "9",
  },
  {
    value: "10px",
    label: "10",
  },
  {
    value: "11px",
    label: "11",
  },
  {
    value: "12px",
    label: "12",
  },
  {
    value: "14px",
    label: "14",
  },
  {
    value: "16px",
    label: "16",
  },
  {
    value: "18px",
    label: "18",
  },
  {
    value: "20px",
    label: "20",
  },
  {
    value: "24px",
    label: "24",
  },
];

const TEMPLATE_PART_OPTIONS = [
  { label: "Header", value: TEMPLATE_PART.HEADER },
  { label: "Body", value: TEMPLATE_PART.BODY },
  { label: "Footer", value: TEMPLATE_PART.FOOTER },
];

export default {
  name: "ReportTemplateEditor",
  mixins: [contentHeightMixin],

  components: {
    EditorContent,
    ImageForm,
    LogicMenu,
    ReportStatementDialog,
    CellPaddingForm,
    ColorForm,
    TableBorderMenu,
    InsertMultipleImagesDialog,
    TableMenu,
    TemplateCodeEditor,
    PhotoSizeDialog,
  },
  props: {
    item: Object,
    errors: Array,
    valid: Boolean,
    validated: Boolean,
    multipleItems: Boolean,
    value: String,
    height: {
      type: String,
      default: "250px",
    },
    index: Number,
    maxChars: Number,
    label: String,
    placeholder: String,
    allowMultiple: Boolean,
    disabled: Boolean,
    reportDefinition: Object,
    selectedGisDataFieldName: String,
    gisDataFields: Array,
    selectedTableHtmlString: String,
  },
  data() {
    return {
      editor: undefined,
      editingHtml: false,
      showColorPicker: false,
      showLinkMenu: false,
      showImageLinkMenu: false,
      inputURL: "",
      showTableSettingsMenu: false,
      showTableMenu: false,
      colorSelection: "#000000",
      fontFamilySelection: "Open Sans",
      fontFamilyOptions,
      fontSizeSelection: "14px",
      fontSizeOptions,
      mdiPowerOn,
      mdiChevronDown,
      mdiFormatColorText,
      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatUnderline,
      mdiLink,
      mdiLinkOff,
      mdiFormatAlignLeft,
      mdiFormatAlignRight,
      mdiFormatAlignCenter,
      mdiFormatAlignJustify,
      mdiTable,
      mdiTableColumnPlusBefore,
      mdiTableColumnPlusAfter,
      mdiTableColumnRemove,
      mdiTableRowPlusBefore,
      mdiTableRowPlusAfter,
      mdiTableRowRemove,
      mdiTableRemove,
      mdiTableMergeCells,
      mdiTableSplitCell,
      mdiTableCog,
      mdiUndo,
      mdiRedo,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiImage,
      mdiFormatPageBreak,
      mdiCodeTags,
      mdiFileDocumentEdit,
      mdiPlus,
      mdiFormatColorFill,
      mdiBorderAllVariant,
      mdiBorderNoneVariant,
      mdiBorderTopVariant,
      mdiBorderBottomVariant,
      mdiBorderLeftVariant,
      mdiBorderRightVariant,
      mdiCog,
      mdiDotsVertical,
      mdiLightbulbOnOutline,
      mdiChevronLeft,
      showLogicMenu: false,
      selectedLogicStatement: undefined,
      showStatementDialog: false,
      cellColorSelection: "#000000",
      showCellColorPicker: false,
      borderStyle: BORDER_STYLE.NONE,
      quantityPadding: 3,
      showCellMenu: false,
      showInsertMultipleImagesDialog: false,
      selectedFormField: undefined,
      showPreviewTemplateDialog: false,
      showPhotoSizeDialog: false,
      TEMPLATE_PART_OPTIONS,
      templatePart: TEMPLATE_PART.BODY,
      templateCodeEditorValue: "",
      showDocSettingsDialog: false,
      headerEditor: undefined,
      bodyEditor: undefined,
      footerEditor: undefined,
      TEMPLATE_PART,
      contentWidth: window.innerWidth - 420,
      contentHeight: window.innerHeight - 256,
      observer: undefined,
    };
  },
  watch: {
    value(val) {
      this.bodyEditor.commands.setContent(val);
    },
    "reportDefinition.html_merge_definition_header"(val) {
      this.headerEditor.commands.setContent(val);
    },
    "reportDefinition.html_merge_definition_footer"(val) {
      this.footerEditor.commands.setContent(val);
    },
    selectedGisDataFieldName(gisDataFieldName) {
      if (!gisDataFieldName) {
        return;
      }
      this.insertPlaceholder(gisDataFieldName.replaceAll(" ", "_"));
    },
    selectedTableHtmlString(tableHtmlString) {
      if (!tableHtmlString) {
        return;
      }
      this.editor.commands.insertContent(tableHtmlString);
    },
    templatePart: {
      immediate: true,
      handler(val) {
        const { headerEditor, bodyEditor, footerEditor } = this;
        if (!headerEditor || !bodyEditor || !footerEditor) {
          return;
        }
        if (val === TEMPLATE_PART.HEADER) {
          this.editor = this.headerEditor;
        } else if (val === TEMPLATE_PART.BODY) {
          this.editor = this.bodyEditor;
        } else if (val === TEMPLATE_PART.FOOTER) {
          this.editor = this.footerEditor;
        }
        this.editor.commands.setFontFamily("Open Sans");
        this.editor.commands.setFontSize(this.fontSizeSelection);
        this.editor.commands.setColor("#000");
        this.$emit("template-part-changed", val);
      },
    },
    "$vuetify.breakpoint.lgAndUp"() {
      this.setMenuState(false);
    },
    disabled: {
      immediate: true,
      handler(disabled) {
        this.headerEditor?.setOptions?.({ editable: !disabled });
        this.bodyEditor?.setOptions?.({ editable: !disabled });
        this.footerEditor?.setOptions?.({ editable: !disabled });
      },
    },
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    openCodeEditor(templatePart) {
      this.templatePart = templatePart;
      if (templatePart === TEMPLATE_PART.HEADER) {
        this.templateCodeEditorValue = this.headerEditor.getHTML();
      } else if (templatePart === TEMPLATE_PART.BODY) {
        this.templateCodeEditorValue = this.bodyEditor.getHTML();
      } else if (templatePart === TEMPLATE_PART.FOOTER) {
        this.templateCodeEditorValue = this.footerEditor.getHTML();
      }
      this.editingHtml = true;
    },
    insertPageBreak() {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: "PageBreak",
            attrs: {
              pageBreakClass: "pageBreak",
            },
          },
        ])
        .run();
    },
    onEditorInput(content) {
      if (!this.editor) {
        return;
      }
      if (this.templatePart === TEMPLATE_PART.HEADER) {
        if (this.editingHtml) {
          this.headerEditor.commands.setContent(content);
        }
        this.$emit("input:header", content);
      } else if (this.templatePart === TEMPLATE_PART.BODY) {
        if (this.editingHtml) {
          this.bodyEditor.commands.setContent(content);
        }
        this.$emit("input", content);
      } else if (this.templatePart === TEMPLATE_PART.FOOTER) {
        if (this.editingHtml) {
          this.footerEditor.commands.setContent(content);
        }
        this.$emit("input:footer", content);
      }
    },
    onInsertTable({ numRow, numCol }) {
      this.showTableMenu = false;
      this.editor
        .chain()
        .focus()
        .insertTable({
          rows: numRow,
          cols: numCol,
          withHeaderRow: false,
        })
        .run();
      this.editor
        .chain()
        .focus()
        .setCellAttribute("borderLeft", "1px solid black")
        .setCellAttribute("borderRight", "1px solid black")
        .setCellAttribute("borderTop", "1px solid black")
        .setCellAttribute("borderBottom", "1px solid black")
        .run();
      for (let i = 1; i <= numRow * numCol; i++) {
        this.editor
          .chain()
          .focus()
          .goToNextCell()
          .setCellAttribute("borderLeft", "1px solid black")
          .setCellAttribute("borderRight", "1px solid black")
          .setCellAttribute("borderTop", "1px solid black")
          .setCellAttribute("borderBottom", "1px solid black")
          .run();
      }
    },
    onTableBorderSaved(borderStyle) {
      this.borderStyle = borderStyle;
      let border = {
        borderTop: "0px",
        borderRight: "0px",
        borderBottom: "0px",
        borderLeft: "0px",
      };
      if (borderStyle === BORDER_STYLE.TOP) {
        border = {
          borderTop: "1px",
          borderRight: "0px",
          borderBottom: "0px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.BOTTOM) {
        border = {
          borderTop: "0px",
          borderRight: "0px",
          borderBottom: "1px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT) {
        border = {
          borderTop: "0px",
          borderRight: "0px",
          borderBottom: "0px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.RIGHT) {
        border = {
          borderTop: "0px",
          borderRight: "1px",
          borderBottom: "0px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.ALL) {
        border = {
          borderTop: "1px",
          borderRight: "1px",
          borderBottom: "1px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.NONE) {
        border = {
          borderTop: "0px",
          borderRight: "0px",
          borderBottom: "0px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_BOTTOM) {
        border = {
          borderTop: "0px",
          borderRight: "0px",
          borderBottom: "1px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_BOTTOM_RIGHT) {
        border = {
          borderTop: "0px",
          borderRight: "1px",
          borderBottom: "1px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_RIGHT) {
        border = {
          borderTop: "0px",
          borderRight: "1px",
          borderBottom: "0px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_TOP) {
        border = {
          borderTop: "1px",
          borderRight: "0px",
          borderBottom: "0px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_TOP_BOTTOM) {
        border = {
          borderTop: "1px",
          borderRight: "0px",
          borderBottom: "1px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.LEFT_TOP_RIGHT) {
        border = {
          borderTop: "1px",
          borderRight: "1px",
          borderBottom: "0px",
          borderLeft: "1px",
        };
      } else if (borderStyle === BORDER_STYLE.RIGHT_BOTTOM) {
        border = {
          borderTop: "0px",
          borderRight: "1px",
          borderBottom: "1px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.TOP_BOTTOM) {
        border = {
          borderTop: "1px",
          borderRight: "0px",
          borderBottom: "1px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.TOP_RIGHT) {
        border = {
          borderTop: "1px",
          borderRight: "1px",
          borderBottom: "0px",
          borderLeft: "0px",
        };
      } else if (borderStyle === BORDER_STYLE.TOP_RIGHT_BOTTOM) {
        border = {
          borderTop: "1px",
          borderRight: "1px",
          borderBottom: "1px",
          borderLeft: "0px",
        };
      }
      this.saveBorderStyle(border);
    },
    saveBorderStyle({ borderLeft, borderRight, borderTop, borderBottom }) {
      this.editor
        .chain()
        .focus()
        .setCellAttribute(
          "borderLeft",
          borderLeft !== "0px" ? `${borderLeft} solid black` : undefined
        )
        .setCellAttribute(
          "borderRight",
          borderRight !== "0px" ? `${borderRight} solid black` : undefined
        )
        .setCellAttribute(
          "borderTop",
          borderTop !== "0px" ? `${borderTop} solid black` : undefined
        )
        .setCellAttribute(
          "borderBottom",
          borderBottom !== "0px" ? `${borderBottom} solid black` : undefined
        )
        .setCellAttribute(
          "borderWidth",
          borderLeft === "0px" &&
            borderRight === "0px" &&
            borderTop === "0px" &&
            borderBottom === "0px"
            ? ""
            : undefined
        )
        .setCellAttribute(
          "borderStyle",
          borderLeft === "0px" &&
            borderRight === "0px" &&
            borderTop === "0px" &&
            borderBottom === "0px"
            ? ""
            : undefined
        )
        .run();
    },
    onTextColorSaved(colorSelection) {
      this.editor.chain().focus().setColor(colorSelection).run();
      this.colorSelection = colorSelection;
      this.showColorPicker = false;
    },
    onCellBackgroundColorSaved(colorSelection) {
      this.editor
        .chain()
        .focus()
        .setCellAttribute("backgroundColor", colorSelection)
        .run();
      this.cellColorSelection = colorSelection;
      this.showCellColorPicker = false;
    },
    onCellPaddingSaved(quantityPadding) {
      this.editor
        .chain()
        .focus()
        .setCellAttribute("padding", `${quantityPadding}px`)
        .run();
      this.showCellMenu = false;
    },
    insertPhotos(formField) {
      this.selectedFormField = formField;
      if (formField.allowMultiple) {
        this.showInsertMultipleImagesDialog = true;
      } else {
        this.showPhotoSizeDialog = true;
      }
    },
    insertPlaceholder(placeholder) {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: "param",
            attrs: {
              param: placeholder,
            },
          },
        ])
        .run();
    },
    onStatementSaved(statement) {
      this.showStatementDialog = false;
      this.editor.chain().focus().insertContent(`{% ${statement} %}`).run();
    },
    onLogicChoiceSelected(selectedLogicStatement) {
      if (selectedLogicStatement === LOGIC.END_IF) {
        this.editor.chain().focus().insertContent("{% endif %}").run();
        return;
      } else if (selectedLogicStatement === LOGIC.ELSE) {
        this.editor.chain().focus().insertContent("{% else %}").run();
        return;
      }
      this.showStatementDialog = true;
      this.selectedLogicStatement = selectedLogicStatement;
    },
    onFontFamilyChange(fontFamily) {
      this.fontFamilySelection = fontFamily;
      this.editor.chain().focus().setFontFamily(this.fontFamilySelection).run();
    },
    onFontSizeChange(fontSize) {
      this.fontSizeSelection = fontSize;
      this.editor.commands.setFontSize(this.fontSizeSelection);
    },
    getLink() {
      if (this.editor.isActive("link")) {
        this.inputURL = this.editor.getAttributes("link").href;
      } else {
        this.inputURL = "";
      }
    },
    setLink(url) {
      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    addImage({ inputImageURL, imageWidth, imageHeight }) {
      this.showImageLinkMenu = false;
      if (inputImageURL) {
        this.editor
          .chain()
          .focus()
          .setResizableImage({
            src: inputImageURL,
            width: imageWidth,
            height: imageHeight,
          })
          .run();
      }
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.contentWidth = window.innerWidth - 59;
      } else {
        this.contentWidth = window.innerWidth - 359;
      }

      if (window.innerWidth <= 600) {
        this.contentHeight =
          window.innerHeight - 80 - this.$refs?.topBar?.clientHeight ?? 0;
      } else {
        this.contentHeight =
          window.innerHeight - 56 - this.$refs?.topBar?.clientHeight ?? 0;
      }
    },
  },
  computed: {
    pageSettings() {
      const defaultPageSettings = {
        pageWidth: 8.5,
        pageHeight: 11,
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
      };
      return this.reportDefinition?.report?.pageSettings ?? defaultPageSettings;
    },
    showTableTypeButtons() {
      return (
        this.editor.can().addColumnBefore() ||
        this.editor.can().addColumnAfter() ||
        this.editor.can().deleteColumn() ||
        this.editor.can().addRowBefore() ||
        this.editor.can().addRowAfter() ||
        this.editor.can().deleteRow() ||
        this.editor.can().deleteTable()
      );
    },
    fontFamilyValue() {
      if (!this.editor.getAttributes("textStyle").fontFamily) {
        return this.fontFamilySelection;
      }
      return this.editor.getAttributes("textStyle").fontFamily;
    },
    fontSizeValue() {
      if (!this.editor.getAttributes("textStyle").fontSize) {
        return this.fontSizeSelection;
      }
      return this.editor.getAttributes("textStyle").fontSize;
    },
    showTableSettingsButton() {
      return (
        this.editor.can().addColumnBefore() ||
        this.editor.can().addColumnAfter() ||
        this.editor.can().deleteColumn() ||
        this.editor.can().addRowBefore() ||
        this.editor.can().addRowAfter() ||
        this.editor.can().deleteRow() ||
        this.editor.can().deleteTable()
      );
    },
    formFields() {
      return this.reportDefinition.form.sections
        .map((s) => s.items)
        .flat()
        .filter((item) => item.type === "QUESTION");
    },
  },
  mounted() {
    const headerContent = this.reportDefinition.html_merge_definition_header;
    this.headerEditor = new Editor({
      content: headerContent,
      useBuiltInExtensions: false,
      extensions: [
        CustomTable.configure({
          resizable: true,
          lastColumnResizable: false,
        }),
        Document.extend({
          content: "(block | ImagePlaceholder)*",
        }),
        StarterKit.configure({
          paragraph: false,
          document: false,
        }),
        CustomParagraph.configure({
          HTMLAttributes: {
            formVersion: this.reportDefinition.html_merge_definition_version,
          },
        }),
        TableRow,
        TableHeader,
        ResizableImage,
        Underline,
        TextAlign.configure({ types: ["heading", "paragraph", "tableCell"] }),
        Color.configure({
          types: ["textStyle"],
        }),
        TextStyle,
        FontFamily,
        FontSize,
        StatementNode,
        ParamPlaceholder,
        ImagePlaceholder,
        SignaturePlaceholder,
        MissingParamPlaceholder,
        CustomTableCell,
        MissingImagePlaceholder,
        MissingSignaturePlaceholder,
        PageBreak,
        CustomParagraph,
        CustomLink,
        Div,
        Section,
        ParamPlaceholderBlock,
        MissingParamPlaceholderBlock,
      ],
      onUpdate: () => {
        this.$emit("input:header", this.headerEditor.getHTML());
      },
    });
    this.headerEditor.commands.setFontFamily("Open Sans");
    this.headerEditor.commands.setFontSize(this.fontSizeSelection);
    this.headerEditor.commands.setColor("#000");

    const bodyContent = this.value;
    this.bodyEditor = new Editor({
      content: bodyContent,
      useBuiltInExtensions: false,
      extensions: [
        CustomTable.configure({
          resizable: true,
          lastColumnResizable: false,
        }),
        Document.extend({
          content: "(block | ImagePlaceholder)*",
        }),
        StarterKit.configure({
          paragraph: false,
          document: false,
        }),
        CustomParagraph.configure({
          HTMLAttributes: {
            formVersion: this.reportDefinition.html_merge_definition_version,
            style: `font-size: ${
              this.reportDefinition.html_merge_definition_version === 2 ||
              this.reportDefinition.html_merge_definition_version === 3
                ? "16px"
                : "8px"
            }`,
          },
        }),
        TableRow,
        TableHeader,
        ResizableImage,
        Underline,
        TextAlign.configure({ types: ["heading", "paragraph", "tableCell"] }),
        Color.configure({
          types: ["textStyle"],
        }),
        TextStyle,
        FontFamily,
        FontSize,
        StatementNode,
        ParamPlaceholder,
        ImagePlaceholder,
        SignaturePlaceholder,
        MissingParamPlaceholder,
        CustomTableCell,
        MissingImagePlaceholder,
        MissingSignaturePlaceholder,
        PageBreak,
        CustomParagraph,
        CustomLink,
        Div,
        Section,
        ParamPlaceholderBlock,
        MissingParamPlaceholderBlock,
      ],
      onUpdate: () => {
        this.$emit("input", this.bodyEditor.getHTML());
      },
    });
    this.bodyEditor.commands.setFontFamily("Open Sans");
    this.bodyEditor.commands.setFontSize(this.fontSizeSelection);
    this.bodyEditor.commands.setColor("#000");

    const footerContent = this.reportDefinition.html_merge_definition_footer;
    this.footerEditor = new Editor({
      content: footerContent,
      useBuiltInExtensions: false,
      extensions: [
        CustomTable.configure({
          resizable: true,
          lastColumnResizable: false,
        }),
        Document.extend({
          content: "(block | ImagePlaceholder)*",
        }),
        StarterKit.configure({
          paragraph: false,
          document: false,
        }),
        CustomParagraph.configure({
          HTMLAttributes: {
            formVersion: this.reportDefinition.html_merge_definition_version,
          },
        }),
        TableRow,
        TableHeader,
        ResizableImage,
        Underline,
        TextAlign.configure({ types: ["heading", "paragraph", "tableCell"] }),
        Color.configure({
          types: ["textStyle"],
        }),
        TextStyle,
        FontFamily,
        FontSize,
        StatementNode,
        ParamPlaceholder,
        ImagePlaceholder,
        SignaturePlaceholder,
        MissingParamPlaceholder,
        CustomTableCell,
        MissingImagePlaceholder,
        MissingSignaturePlaceholder,
        PageBreak,
        CustomParagraph,
        CustomLink,
        Div,
        Section,
        ParamPlaceholderBlock,
        MissingParamPlaceholderBlock,
      ],
      onUpdate: () => {
        this.$emit("input:footer", this.footerEditor.getHTML());
      },
    });
    this.footerEditor.commands.setFontFamily("Open Sans");
    this.footerEditor.commands.setFontSize(this.fontSizeSelection);
    this.footerEditor.commands.setColor("#000");
    if (this.templatePart === TEMPLATE_PART.HEADER) {
      this.editor = this.headerEditor;
    } else if (this.templatePart === TEMPLATE_PART.BODY) {
      this.editor = this.bodyEditor;
    } else if (this.templatePart === TEMPLATE_PART.FOOTER) {
      this.editor = this.footerEditor;
    }

    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.observer = new ResizeObserver(() => {
      this.onResize();
    });
    this.observer.observe(this.$refs?.topBar);

    this.headerEditor?.setOptions?.({ editable: !this.disabled });
    this.bodyEditor?.setOptions?.({ editable: !this.disabled });
    this.footerEditor?.setOptions?.({ editable: !this.disabled });
  },
  beforeDestroy() {
    this.editor.destroy();
    this.observer?.disconnect();
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
/* Table-specific styling */
.template-editor::v-deep .ProseMirror {
  table {
    overflow: hidden;

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}

.template-editor::v-deep .tableWrapper {
  overflow-x: auto;
}

.template-editor::v-deep .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.is-active {
  background: #aab1aa;
}

.v-btn--round {
  border-radius: unset !important;
}

.main-btn {
  justify-content: left !important;
}

.actions-btn {
  padding: 0 !important;
  width: 15px !important;
  margin-left: -12px !important;
}

.v-select__selections input {
  display: none !important;
}

.v-select__selection {
  max-width: 100% !important;
}

.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
}

.v-menu__content {
  max-height: 65%;
}

.v-btn--icon.v-size--default {
  height: 28px;
  width: 28px;
}
</style>

<style>
.ProseMirror-focused {
  outline: none;
}
</style>

<style lang="scss" scoped>
.template-editor::v-deep .ProseMirror {
  padding: 0px;

  @page {
    counter-increment: page;

    @bottom-left {
      padding-right: 10px;
      content: "Page " counter(page);
    }
  }

  table {
    border: 1px #cacaca;
    page-break-inside: auto;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
    width: 100%;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  th {
    font-weight: bold;
    background-color: gainsboro;
  }

  td,
  th {
    border: 1px solid #cacaca;
    padding: 4px;
    min-width: 1em;
    vertical-align: top;
    position: relative;
  }

  a {
    color: #0000ee;
  }

  p {
    margin: 0;
    color: #000;
    line-height: 1.36;
    font-size: v-bind(
      'reportDefinition.html_merge_definition_version === 2 || reportDefinition.html_merge_definition_version === 3 ? "16px" : "8px"'
    );
  }

  .page {
    position: relative;
    width: 100%;
  }

  .borderTop {
    border-top: 1px solid;
  }

  .borderLeft {
    border-left: 1px solid;
  }

  .borderRight {
    border-right: 1px solid;
  }

  .borderBottom {
    border-bottom: 1px solid;
  }

  .borderTopThick {
    border-top: 2px solid;
  }

  .borderLeftThick {
    border-left: 2px solid;
  }

  .borderRightThick {
    border-right: 2px solid;
  }

  .borderBottomThick {
    border-bottom: 2px solid;
  }

  .borderTopNone {
    border-top: none;
  }

  .borderBottomNone {
    border-bottom: none;
  }

  .borderLeftNone {
    border-left: none;
  }

  .borderRightNone {
    border-right: none;
  }

  .borderTopOnly {
    border-bottom: 0;
    border-top: 1px solid;
    border-left: 0;
    border-right: 0;
  }

  .borderBottomOnly {
    border-bottom: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .borderRightOnly {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 1px solid;
  }

  .borderLeftOnly {
    border-bottom: 0;
    border-top: 0;
    border-left: 1px solid;
    border-right: 0;
  }

  .noBorder {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .pageBreak {
    page-break-before: always;
  }

  #pageBreak {
    page-break-before: always;
  }

  .photoHolder {
    padding: 0px 25px;
  }

  .signature {
    position: relative;
    left: 15px;
    bottom: -1px;
    height: 30px;
  }

  .question {
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-top: 1px solid;
    border-right: 0;
  }

  .answer {
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-left: 0;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.background {
  background-color: #e4e6e4;
}
</style>
