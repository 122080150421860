export const reportDefinition = {
  report: {
    emailSettings: [],
    emails: [],
    reportDescription: {
      canEditAfterFinal: false,
      description: "",
      title: "Untitled Report",
    },
  },
};
