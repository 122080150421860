var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex flex-column justify-center align-center my-0 py-3" },
    [
      _vm.showPageSettings
        ? _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  _vm.showReportPageSettingsDialog = true
                },
              },
            },
            [_vm._v(" Page Settings ")]
          )
        : _vm._e(),
      _vm.showReportPageSettingsDialog
        ? _c("ReportPageSettingsDialog", {
            attrs: {
              showReportPageSettingsDialog: _vm.showReportPageSettingsDialog,
              value: _vm.value,
              disabled: _vm.disabled,
            },
            on: {
              "close-doc-settings-dialog": function ($event) {
                _vm.showReportPageSettingsDialog = false
              },
              input: _vm.onInputDocSettings,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }