var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showReportBuilderEditFormCloseDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v(" Unsaved Changes ")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("reverse-changes")
                },
              },
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "rounded-0" },
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _vm._v(
                  " You have unsaved changes to your report. Do you want to close the form without saving? "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "report-builder-edit-form-save-and-close"
                            )
                          },
                        },
                      },
                      [_vm._v(" Save and Close ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("report-builder-edit-form-close")
                          },
                        },
                      },
                      [_vm._v(" Close Without Saving ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }