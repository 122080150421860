<template>
  <v-dialog
    :value="showStatementDialog"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-card>
      <validation-observer
        ref="logicStatementForm"
        slim
        v-slot="{ valid: isFormValid }"
      >
        <form @submit.prevent="insertLogicStatement">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>
              {{ selectedLogicStatement | formTemplateLogicType }}
              Statement
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('close-statement-dialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="elevation-0">
            <section
              class="d-flex align-center gap"
              v-for="(clause, index) of statementClauses"
              :key="clause.id"
              :class="{
                'flex-no-wrap': $vuetify.breakpoint.smAndUp,
                'flex-wrap': $vuetify.breakpoint.xsOnly,
              }"
            >
              <div class="body-1 mt-4 width-1" v-if="index === 0">
                {{ selectedLogicStatement | formTemplateLogicType }}
              </div>
              <validation-provider
                v-else
                v-slot="{ errors, valid }"
                name="Operator"
                rules="required"
                :class="{
                  'width-1': $vuetify.breakpoint.smAndUp,
                  'full-width': $vuetify.breakpoint.xsOnly,
                }"
              >
                <v-select
                  autocomplete="off"
                  hide-details
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="operator"
                  :items="OPERATOR_CHOICES"
                  item-text="label"
                  item-value="value"
                  v-model="clause.operator"
                >
                </v-select>
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="UtiliSync Field"
                rules="required"
                :class="{
                  'width-2': $vuetify.breakpoint.smAndUp,
                  'full-width': $vuetify.breakpoint.xsOnly,
                }"
              >
                <v-select
                  autocomplete="off"
                  label="UtiliSync Field"
                  hide-details
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="gisDataField"
                  :items="gisDataFieldChoices"
                  item-text="label"
                  item-value="value"
                  v-model="clause.gisDataField"
                >
                </v-select>
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Comparison"
                rules="required"
                :class="{
                  'width-2': $vuetify.breakpoint.smAndUp,
                  'full-width': $vuetify.breakpoint.xsOnly,
                }"
              >
                <v-select
                  autocomplete="off"
                  labe="Comparison"
                  :items="getLogicComparisonChoices(clause.gisDataField)"
                  hide-details
                  color="primary"
                  name="comparison"
                  item-value="value"
                  :error-messages="errors"
                  :success="valid"
                  v-model="clause.comparison"
                >
                  <template v-slot:item="{ item }">
                    <section class="text-truncate no-wrap">
                      <LogicComparisonIcon :value="item.value" />
                      {{ item.label }}
                    </section>
                  </template>

                  <template v-slot:selection="{ item }">
                    <section class="text-truncate no-wrap">
                      <LogicComparisonIcon :value="item.value" selected />
                      {{ item.label }}
                    </section>
                  </template>
                </v-select>
              </validation-provider>

              <div
                :class="{
                  'width-3': $vuetify.breakpoint.smAndUp,
                  'full-width': $vuetify.breakpoint.xsOnly,
                }"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Value"
                  rules="required"
                  v-if="
                    clause.comparison === LOGIC_COMPARISON.EQUALS &&
                    getGisDataFieldOptions(clause.gisDataField).length > 0
                  "
                >
                  <v-select
                    autocomplete="off"
                    labe="Value"
                    :items="getSelectValueOptions(clause.gisDataField)"
                    hide-details
                    color="primary"
                    name="value"
                    item-value="value"
                    item-text="label"
                    :error-messages="errors"
                    :success="valid"
                    v-model="clause.value"
                  >
                  </v-select>
                </validation-provider>
                <validation-provider
                  v-else
                  v-slot="{ errors, valid }"
                  name="Value"
                  rules="required"
                >
                  <v-text-field
                    v-model="clause.value"
                    label="Value"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="value"
                  />
                </validation-provider>
              </div>

              <div class="body-1 mt-4">
                <v-btn
                  icon
                  :disabled="index === 0"
                  @click="removeClause(index)"
                >
                  <v-icon v-if="index > 0" color="#F2545B">
                    {{ mdiDelete }}
                  </v-icon>
                </v-btn>
              </div>
            </section>

            <section>
              <v-btn
                class="px-0 mx-0"
                color="primary"
                text
                :disabled="statementClauses.length === 1 && !isFormValid"
                @click="insertClause"
              >
                <v-icon small>
                  {{ mdiPlus }}
                </v-icon>
                <div>Additional Condition</div>
              </v-btn>
            </section>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              type="submit"
              color="primary"
              :disabled="!isFormValid"
              :dark="isFormValid"
            >
              Insert Logic
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { LOGIC_COMPARISON, OPERATOR, LOGIC } from "@/constants/logicChoices";
import { v4 as uuidv4 } from "uuid";
import { mdiPlus, mdiDelete } from "@mdi/js";
import FIELD_TYPES from "@/constants/gisDataFields";
import LogicComparisonIcon from "@/components/shared/LogicComparisonIcon";
import { snakeCase } from "lodash";

const LOGIC_COMPARISON_CHOICES = [
  {
    label: "Equals",
    value: LOGIC_COMPARISON.EQUALS,
  },
  {
    label: "Does Not Equal",
    value: LOGIC_COMPARISON.DOES_NOT_EQUAL,
  },
  {
    label: "Contains",
    value: LOGIC_COMPARISON.CONTAINS,
  },
  {
    label: "Not Empty",
    value: LOGIC_COMPARISON.NOT_EMPTY,
  },
];

const NUMBER_COMPARISON_CHOICES = [
  {
    label: "Equals",
    value: LOGIC_COMPARISON.EQUALS,
  },
  {
    label: "Does Not Equal",
    value: LOGIC_COMPARISON.DOES_NOT_EQUAL,
  },
  {
    label: "Greater Than",
    value: LOGIC_COMPARISON.GREATER_THAN,
  },
  {
    label: "Greater Than or Equal",
    value: LOGIC_COMPARISON.GREATER_THAN_OR_EQUAL,
  },
  {
    label: "Less Than",
    value: LOGIC_COMPARISON.LESS_THAN,
  },
  {
    label: "Less Than or Equal",
    value: LOGIC_COMPARISON.LESS_THAN_OR_EQUAL,
  },
  {
    label: "Contains",
    value: LOGIC_COMPARISON.CONTAINS,
  },
  {
    label: "Not Empty",
    value: LOGIC_COMPARISON.NOT_EMPTY,
  },
];

const OPERATOR_CHOICES = [
  { label: "AND", value: OPERATOR.AND },
  { label: "OR", value: OPERATOR.OR },
];

const buildStatement = (
  selectedLogicStatement,
  statementClauses,
  gisDataFields
) => {
  let logicStatement = "";
  if (selectedLogicStatement === LOGIC.IF) {
    logicStatement = "if";
  } else if (selectedLogicStatement === LOGIC.ELSE_IF) {
    logicStatement = "elif";
  } else if (selectedLogicStatement === LOGIC.ELSE) {
    logicStatement = "else";
  }

  const statement = statementClauses
    .map((c, index) => {
      const { gisDataField: gisDataFieldId, value, operator } = c;
      const gisDataField = gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
      const gisDataFieldName = gisDataField?.name;
      const snakeCaseFieldName = snakeCase(gisDataFieldName);
      const prefix = index === 0 ? logicStatement : operator;
      let formattedValue = value;
      if (typeof value === "string") {
        formattedValue = `'${value}'`;
      }

      if (c.comparison === LOGIC_COMPARISON.EQUALS) {
        return `${prefix} ${snakeCaseFieldName} == '${value}'`;
      } else if (c.comparison === LOGIC_COMPARISON.DOES_NOT_EQUAL) {
        return `${prefix} ${snakeCaseFieldName} != ${formattedValue}`;
      } else if (c.comparison === LOGIC_COMPARISON.CONTAINS) {
        return `${prefix} ${snakeCaseFieldName} in ${value}`;
      } else if (c.comparison === LOGIC_COMPARISON.NOT_EMPTY) {
        return `${prefix} ${snakeCaseFieldName}|length > 0`;
      } else if (c.comparison === LOGIC_COMPARISON.GREATER_THAN) {
        return `${prefix} ${snakeCaseFieldName} > ${value}`;
      } else if (c.comparison === LOGIC_COMPARISON.GREATER_THAN_OR_EQUAL) {
        return `${prefix} ${snakeCaseFieldName} >= ${value}`;
      } else if (c.comparison === LOGIC_COMPARISON.LESS_THAN) {
        return `${prefix} ${snakeCaseFieldName} < ${value}`;
      } else if (c.comparison === LOGIC_COMPARISON.LESS_THAN_OR_EQUAL) {
        return `${prefix} ${snakeCaseFieldName} <= ${value}`;
      }
    })
    .join(" ");
  return statement;
};

export default {
  name: "StatementDialog",
  components: { LogicComparisonIcon },
  props: {
    showStatementDialog: Boolean,
    selectedLogicStatement: String,
    gisDataFields: Array,
  },
  computed: {
    gisDataFieldChoices() {
      return this.gisDataFields.map((f) => {
        const { gis_data_field_id: value, name: label } = f;
        return { label, value };
      });
    },
  },
  data() {
    return {
      LOGIC_COMPARISON,
      LOGIC_COMPARISON_CHOICES,
      OPERATOR_CHOICES,
      FIELD_TYPES,
      statementClauses: [
        {
          id: uuidv4(),
          gisDataField: undefined,
          comparison: LOGIC_COMPARISON.EQUALS,
          value: undefined,
        },
      ],
      mdiPlus,
      mdiDelete,
    };
  },
  methods: {
    removeClause(index) {
      this.statementClauses.splice(index, 1);
    },
    insertClause() {
      const statementClause = {
        id: uuidv4(),
        operator: OPERATOR.AND,
        gisDataField: undefined,
        comparison: LOGIC_COMPARISON.EQUALS,
        value: undefined,
      };
      this.statementClauses.push(statementClause);
    },
    async insertLogicStatement() {
      const success = await this.$refs.logicStatementForm.validate();
      if (!success) {
        return;
      }
      const statement = buildStatement(
        this.selectedLogicStatement,
        this.statementClauses,
        this.gisDataFields
      );
      this.$emit("statement-saved", statement, this.selectedLogicStatement);
    },
    getLogicComparisonChoices(gisDataFieldId) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.id === gisDataFieldId
      );
      const gisDataFieldType = gisDataField?.type;
      if ([FIELD_TYPES.NUMBER].includes(gisDataFieldType)) {
        return NUMBER_COMPARISON_CHOICES;
      }
      return LOGIC_COMPARISON_CHOICES;
    },
    getGisDataFieldOptions(gisDataFieldId) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
      const gisDataFieldOptions = gisDataField?.gis_data_field_options;
      if (Array.isArray(gisDataFieldOptions)) {
        return gisDataFieldOptions;
      }
      return [];
    },
    getSelectValueOptions(gisDataFieldId) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
      return gisDataField.gis_data_field_options.map((o) => {
        const { value, label } = o;
        return { value, label };
      });
    },
  },
};
</script>

<style scoped>
.width-1 {
  width: 65px;
}

.width-2 {
  width: 140px;
}

.width-2 >>> .v-select.v-input--is-dirty input {
  width: 0;
}

.width-2 >>> .v-select__selection {
  max-width: none;
}

.width-2 >>> .v-select__selections {
  width: 140px;
  overflow: hidden;
  flex-wrap: nowrap;
}

.width-2 >>> .v-select__selections .v-select__selection {
  min-width: fit-content;
}

.width-2 >>> .v-select:not(.v-input--is-dirty) input {
  width: 140px;
}
.width-3 {
  max-width: 140px;
}

.full-width {
  width: 100%;
}

.no-wrap {
  white-space: nowrap;
}
</style>
