<template>
  <v-main fluid height="100vh" width="100%" max-width="100%">
    <v-toolbar
      class="white--text elevation-0 flex-grow-0 top-bar mt-n14 mx-0 px-0"
      height="56px"
    >
      <v-toolbar-title class="d-flex align-center">
        <v-icon
          color="white"
          @click="showMenuText = !showMenuText"
          class="mx-0"
        >
          {{ mdiMenu }}
        </v-icon>

        <div class="px-8">
          {{ reportDefinition.report.reportDescription.title }}
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        dark
        type="submit"
        outlined
        @click="saveReport"
        id="save-button"
        class="ml-2"
      >
        Save Report
      </v-btn>
      <v-btn icon dark @click="reportBuilderClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <section class="d-flex">
      <section
        :style="{
          height: `calc(100vh - 56px)`,
          width: `56px`,
          'min-width': '56px',
          'max-width': '56px',
        }"
        class="white overflow-y-hidden"
        @mouseenter="showMenuText = true"
        v-if="!showMenuText"
      >
        <v-list
          class="pa-0"
          dense
          ref="formBuilderMenu"
          @mouseenter="showMenuText = true"
        >
          <v-list-item
            @click="activeTab = tabs.REPORT_BUILDER"
            :class="activeTab === tabs.REPORT_BUILDER ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFileDocumentEdit }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="activeTab = tabs.SETTINGS"
            id="settingsTab"
            :class="activeTab === tabs.SETTINGS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCog }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </section>
      <section
        :style="{
          height: `calc(100vh - 56px)`,
          width: `56px`,
          'min-width': '56px',
          'max-width': '56px',
        }"
        class="white overflow-y-hidden"
        @mouseleave="showMenuText = false"
        v-if="showMenuText"
      >
        <v-list
          class="pa-0 border-right"
          dense
          :style="{
            position: 'fixed',
            top: '56px',
            'z-index': 1000,
            height: 'calc(100vh - 56px)',
          }"
        >
          <v-list-item
            @click="activeTab = tabs.REPORT_BUILDER"
            id="settingsTab"
            :class="activeTab === tabs.REPORT_BUILDER ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFileDocumentEdit }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3" v-if="showMenuText">
              Report Builder
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="activeTab = tabs.SETTINGS"
            id="settingsTab"
            :class="activeTab === tabs.SETTINGS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCog }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3" v-if="showMenuText">
              Settings
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </section>

      <form
        class="elevation-0 pa-0 ma-0 flex-grow-1 d-flex"
        :class="{ 'flex-wrap': $vuetify.breakpoint.xsOnly }"
        :style="{
          height: $vuetify.breakpoint.smAndUp ? 'calc(100vh - 56px)' : 'auto',
          'min-width': '0px',
        }"
      >
        <UnsavedReportChangesDialog
          :showReportBuilderEditFormCloseDialog="
            showReportBuilderEditFormCloseDialog
          "
          @report-builder-edit-form-save-and-close="
            onReportBuilderEditFormSaveAndClose
          "
          @report-builder-edit-form-close="
            $emit('report-builder-edit-form-close')
          "
          @reverse-changes="showReportBuilderEditFormCloseDialog = false"
        />

        <section
          :class="{
            'main-page-background': [
              tabs.SETTINGS,
              tabs.REPORT_BUILDER,
            ].includes(activeTab),
            'gray-main-page-background': ![
              tabs.SETTINGS,
              tabs.REPORT_BUILDER,
            ].includes(activeTab),
          }"
          class="flex-grow-1 overflow-y-auto"
        >
          <template v-if="activeTab === tabs.REPORT_BUILDER">
            <validation-observer ref="settingsForm">
              <ReportBuilderTemplateForm
                v-model="reportDefinition"
                :activeTab="activeTab"
                @input="onTemplateChanged()"
              />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.SETTINGS">
            <validation-observer ref="settingsForm">
              <ReportSettingsForm
                v-model="reportDefinition"
                @input="onTemplateChanged()"
              />
            </validation-observer>
          </template>
        </section>
      </form>
    </section>

    <v-snackbar
      v-model="showSavedSnackbar"
      style="z-index: 1001"
      :timeout="3000"
    >
      Report Saved
    </v-snackbar>
  </v-main>
</template>

<script>
import UnsavedReportChangesDialog from "@/components/reports/report-builder-edit-form/UnsavedReportChangesDialog.vue";
import { mdiPlus, mdiMenu, mdiCog, mdiFileDocumentEdit } from "@mdi/js";
import { reportDefinition } from "@/constants/report";
import { cloneDeep, isEqual } from "lodash";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";
import ReportBuilderTemplateForm from "@/components/reports/report-builder-edit-form/ReportBuilderTemplateForm.vue";
import ReportSettingsForm from "@/components/reports/report-builder-edit-form/ReportSettingsForm.vue";

const APIURL = process.env.VUE_APP_API_URL;
const tabs = {
  REPORT_BUILDER: "report-builder",
  SETTINGS: "settings",
};

export default {
  name: "ReportBuilderEditForm",
  props: {
    selectedReportDefinitionId: String,
    reportIsNewReport: Boolean,
  },
  mixins: [permissionsMixin],
  data() {
    const { innerHeight } = this;
    return {
      reportDefinition,
      showDrawer: false,
      showFields: false,
      previewMode: false,
      openSections: [],
      selectedSectionId: 1,
      selectedItemId: undefined,
      selectedControlType: "",
      mdiPlus,
      mdiMenu,
      mdiCog,
      mdiFileDocumentEdit,
      showReportBuilderEditFormCloseDialog: false,
      tabs,
      activeTab: this.$route.query.tab,
      showSavedSnackbar: false,
      savedReport: {},
      selectedReportDefLayer: {},
      leftTabOverflow: false,
      showAdvancedEditDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmDeleteSectionDialog: false,
      innerHeight,
      templateChanged: false,
      showAdvancedFormEditDialog: false,
      showPdfSampleDialog: false,
      addAtIndex: undefined,
      addingField: false,
      reportDefinitionId: undefined,
      showMenuText: false,
    };
  },
  components: {
    UnsavedReportChangesDialog,
    ReportBuilderTemplateForm,
    ReportSettingsForm,
  },
  computed: {
    selectedItem() {
      if (!this.reportDefinition.form) {
        return {};
      }
      const section = this.reportDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      if (section) {
        const { items } = section;
        if (Array.isArray(items)) {
          return items.find((item) => item.id === this.selectedItemId);
        }
      }
      return {};
    },
    hasChanges() {
      return isEqual(this.reportDefinition, this.savedReport);
    },
    isLocateRequestLayer() {
      const { service_type: serviceType } = this.selectedReportDefLayer;
      return serviceType === "L";
    },
    isEsriTokenPresent() {
      return Boolean(localStorage.getItem("esri_token"));
    },
    ...mapGetters(["newReportData"]),
  },
  methods: {
    ...mapMutations(["setNewReportData"]),
    getLeftTabWidth() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return "250px";
      }
      return "100%";
    },
    onTemplateChanged() {
      this.templateChanged = true;
    },
    async reloadDrawer() {
      this.showDrawer = false;
      await this.$nextTick();
      this.showDrawer = true;
    },
    async onReportBuilderEditFormSaveAndClose() {
      this.showReportBuilderEditFormCloseDialog = false;
      await this.saveReport();
      this.$emit("report-builder-edit-form-close");
    },
    isSectionVisible(section) {
      if (
        !this.previewMode ||
        !section.visible ||
        !section.visible?.condition ||
        section.visible?.condition === "ALWAYS"
      ) {
        return true;
      }
      if (section.visible.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.reportDefinition.form };
      const flattenedItems = sections.map(({ items }) => items).flat();
      const dependantItem = flattenedItems.find(
        (item) => item.id === section.visible.dependantId
      );
      const dependantItemValue = dependantItem?.value;
      if (Array.isArray(dependantItemValue)) {
        if (dependantItemValue.length > 1) {
          return false;
        } else {
          const [dependantValue] = dependantItemValue;
          return this.checkSectionDependantValue(
            dependantValue,
            section,
            "visible"
          );
        }
      } else {
        return this.checkSectionDependantValue(
          dependantItemValue,
          section,
          "visible"
        );
      }
    },
    reportBuilderClose() {
      if (!this.hasChanges) {
        this.showReportBuilderEditFormCloseDialog = true;
      } else {
        this.$emit("report-builder-edit-form-close");
      }
    },
    async saveReport() {
      this.templateChanged = false;
      const success = await this.$refs.settingsForm?.validate?.();
      if (this.$refs.settingsForm && !success) {
        return;
      }

      const reportDefinition = cloneDeep(this.reportDefinition);

      if (typeof this.selectedReportDefinitionId === "string") {
        await axios.put(
          `${APIURL}/report_definitions/${this.selectedReportDefinitionId}`,
          reportDefinition
        );
        this.savedReport = cloneDeep(this.reportDefinition);
      } else {
        const { data: savedReport } = await axios.post(
          `${APIURL}/report_definitions`,
          reportDefinition
        );
        this.reportDefinitionId = savedReport.report_definition_id;
        this.savedReport = cloneDeep(this.reportDefinition);
        this.$emit(
          "report-builder-edit-form-submitted",
          this.reportDefinitionId
        );

        if (this.reportIsNewReport) {
          await axios.put(
            `${APIURL}/report_definitions/${this.reportDefinitionId}/is_active`,
            { is_active: true }
          );
        }
      }
      this.showSavedSnackbar = true;
    },
    async onInput() {
      await this.$nextTick();
      this.reportDefinition = cloneDeep(this.reportDefinition);
    },
    async getLayer(reportDefintion) {
      const { map_service_id: mapServiceId } = reportDefintion;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.selectedReportDefLayer = results;
    },
    onResize() {
      const [elHtml] = document.getElementsByTagName("html");
      if (elHtml) {
        elHtml.style.overflowY = "hidden";
      }
      this.innerHeight = window.innerHeight;
    },
    addResizeListener() {
      window.addEventListener("resize", this.onResize);
    },
  },
  beforeMount() {
    if (this.reportIsNewReport) {
      this.saveReport();
    }
    this.addResizeListener();
    this.onResize();
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = null;
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    selectedReportDefinitionId: {
      immediate: true,
      async handler(val) {
        if (typeof val === "string") {
          this.setNewReportData({});
          const { data } = await axios.get(
            `${APIURL}/report_definitions/${val}`
          );
          this.reportDefinition = { ...data };
        } else {
          const {
            title,
            mapServiceId,
            html_merge_definition_version: htmlMergeDefinitionVersion,
          } = this.newReportData;
          this.$set(this.reportDefinition, "map_service_id", mapServiceId);
          this.$set(
            this.reportDefinition,
            "html_merge_definition_version",
            htmlMergeDefinitionVersion
          );
          this.$set(
            this.reportDefinition.report.reportDescription,
            "title",
            title
          );
        }
      },
    },
    selectedItemId(val) {
      if (typeof val === "number") {
        this.selectedWholeSectionId = undefined;
      }
    },
    activeTab(val) {
      if (val !== this.tabs.REPORT_BUILDER) {
        this.showDrawer = false;
      }
      if (this.$route.query.layer) {
        this.$router
          .push({
            path: "/layers",
            query: {
              layer: this.$route.query.layer,
              report: this.$route.query.report,
              tab: val,
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      } else {
        this.$router
          .push({
            query: {
              report: this.$route.query.report,
              tab: val,
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      }
    },
    reportDefinitionId() {
      if (this.$route.query.layer) {
        this.$router
          .push({
            path: "/layers",
            query: {
              layer: this.$route.query.layer,
              report: this.reportDefinitionId,
              tab: "report-builder",
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      } else {
        this.$router
          .push({
            query: {
              report: this.reportDefinitionId,
              tab: "report-builder",
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      }
    },
    showDrawer() {
      const [elHtml] = document.getElementsByTagName("html");
      elHtml.style.overflowY = "hidden";
    },
    "$route.query.forms": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.tab) {
          this.activeTab = this.$route.query.tab;
        } else {
          this.activeTab = "report-builder";
        }
      },
    },
  },
};
</script>

<style>
.list-group {
  width: 100%;
}
</style>

<style scoped>
.selectedField {
  background-color: #fff8e1;
  /* border: 2px #286054 solid; */
}

.line {
  z-index: 9999;
}

.main-page-background {
  background-color: #f1f2f1;
}

.gray-main-page-background {
  background-color: #f1f2f1;
}

.right-pane {
  height: calc(100vh - 125px);
  overflow-y: auto;
}

.highlighted {
  background-color: #dae1df;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.v-list-item {
  position: unset;
}

.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  color: #286054;
}

#preview-form {
  max-width: 600px;
}

#card-actions {
  position: relative;
  z-index: 1000;
}

.v-list-item:hover {
  background-color: #dddddd;
}

.v-list:hover {
  background-color: none !important;
}

.nav-list-items {
  color: #286054;
  font-weight: 500;
}

.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
</style>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-list-item .v-list-item__title {
  font-size: 16px;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
