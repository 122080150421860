import { render, staticRenderFns } from "./ReportPageSettingsDialog.vue?vue&type=template&id=5a2cbdce&scoped=true&"
import script from "./ReportPageSettingsDialog.vue?vue&type=script&lang=js&"
export * from "./ReportPageSettingsDialog.vue?vue&type=script&lang=js&"
import style0 from "./ReportPageSettingsDialog.vue?vue&type=style&index=0&id=5a2cbdce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2cbdce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a2cbdce')) {
      api.createRecord('5a2cbdce', component.options)
    } else {
      api.reload('5a2cbdce', component.options)
    }
    module.hot.accept("./ReportPageSettingsDialog.vue?vue&type=template&id=5a2cbdce&scoped=true&", function () {
      api.rerender('5a2cbdce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reports/report-builder-edit-form/report-builder-template-form/report-configuration-form/ReportPageSettingsDialog.vue"
export default component.exports