<template>
  <v-dialog :value="showAddRelatedRecordDialog" width="500px">
    <v-card class="m-2">
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Add Related Records</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <validation-observer ref="form">
          <section>Selected Related Layer</section>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Layer"
            rules="required"
          >
            <v-autocomplete
              autocomplete="off"
              v-model="childMapServiceId"
              :items="childMapServiceChoices"
              label="Layer"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
            >
            </v-autocomplete>
          </validation-provider>

          <section>Selected related records to be added</section>

          <v-simple-table fixed-header height="30vh">
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">UtiliSync Field</th>
                <th class="text-left"></th>
              </tr>
            </thead>

            <draggable
              :list="childUtiliSyncFields"
              group="childUtiliSyncFields"
              handle=".section-handle"
              tag="tbody"
            >
              <tr
                v-for="(childUtiliSyncField, index) of childUtiliSyncFields"
                :key="childUtiliSyncField.gis_data_field_id"
              >
                <td class="pl-1 pr-0 mx-0 cursor-grab">
                  <v-icon>{{ mdiDragVertical }}</v-icon>
                </td>
                <td>{{ childUtiliSyncField.name }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>{{ mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="removeChildUtiliSyncLayer(index)">
                        <v-list-item-title> Don't Include </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </draggable>
          </v-simple-table>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end align-center px-5 py-3">
        <v-btn color="primary" @click="addRelatedRecords">
          Add Related Records
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import { mdiDragVertical, mdiDotsVertical } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddRelatedRecordsDialog",
  props: {
    showAddRelatedRecordDialog: Boolean,
    mapServiceId: String,
  },
  components: { draggable },
  computed: {
    childMapServiceChoices() {
      return this.mapServices
        .filter((ms) => {
          return ms.parent_map_service_id === this.mapServiceId;
        })
        .map((ms) => {
          const { service_name: label, map_service_id: value } = ms;
          return { label, value };
        });
    },
  },
  data() {
    return {
      childMapServiceId: "",
      mapServices: [],
      childUtiliSyncFields: [],
      gisDataFields: [],
      mdiDragVertical,
      mdiDotsVertical,
    };
  },
  beforeMount() {
    this.getMapServices();
  },
  watch: {
    childMapServiceChoices: {
      deep: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          const [firstChildLayerChoice] = val;
          this.childMapServiceId = firstChildLayerChoice.value;
          this.getGisDataFields();
        }
      },
    },
    childMapServiceId() {
      this.getGisDataFields();
    },
  },
  methods: {
    addRelatedRecords() {
      const layer = this.mapServices.find(
        (ms) => ms.map_service_id === this.childMapServiceId
      );
      const layerName = layer?.service_name;
      const snakeCaseLayerName = layerName.replaceAll(" ", "_");
      const childUtiliSyncFieldNames = this.childUtiliSyncFields.map(
        (f) => f.name
      );
      const ths = childUtiliSyncFieldNames.map((name) => {
        return `<th>${name}</th>`;
      });
      const joinedThs = ths.join("");
      const childFieldTds = childUtiliSyncFieldNames.map((name) => {
        const snakeCaseName = name.replaceAll(" ", "_");
        return `<td><span data-param="attrs.${snakeCaseName}"></span></td>`;
      });
      const joinedTds = childFieldTds.join("");
      const headerRow = `<table><thead><tr>${joinedThs}</tr></thead></table>`;
      const forRow = `<span data-statement="for attrs in ${snakeCaseLayerName}.child"></span>`;
      const tableRow = `<tr>${joinedTds}</tr>`;
      const endForRow = `<span data-statement="endfor"></span>`;
      const tableBody = `${forRow}<table><tbody>${tableRow}</tbody></table>${endForRow}`;
      const table = `${headerRow}\n${tableBody}`;
      this.$emit("added-related-records", table);
    },
    async getMapServices() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`);
      this.mapServices = results;
    },
    async getGisDataFields() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: this.childMapServiceId,
        },
      });
      this.childUtiliSyncFields = results;
    },
    removeChildUtiliSyncLayer(index) {
      this.childUtiliSyncFields.splice(index, 1);
    },
  },
};
</script>
