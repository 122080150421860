var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 mx-auto mt-4",
      attrs: { "max-width": "600", id: "settingsPanel" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "elevation-0" },
        [
          _c("validation-provider", {
            attrs: { slim: "", name: "Title", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors, valid }) {
                  return [
                    _c("v-text-field", {
                      attrs: {
                        label: "Title",
                        "hide-details": "auto",
                        "error-messages": errors,
                        success: valid,
                        color: "primary",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", _vm.reportDefinition)
                        },
                      },
                      model: {
                        value:
                          _vm.reportDefinition.report.reportDescription.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.reportDefinition.report.reportDescription,
                            "title",
                            $$v
                          )
                        },
                        expression:
                          "reportDefinition.report.reportDescription.title",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("section", { staticClass: "py-3" }, [
            _vm._v("Update UtiliSync Fields"),
          ]),
          _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [
                  _vm._v("UtiliSync Field"),
                ]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Value")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.gisDataFields, function (gisDataField, index) {
                return _c("tr", { key: gisDataField.gis_data_field_id }, [
                  _c("td", { staticClass: "py-1" }, [
                    _vm._v(_vm._s(gisDataField.name)),
                  ]),
                  _c(
                    "td",
                    { staticClass: "py-1" },
                    [
                      _c("validation-provider", {
                        attrs: { slim: "", name: "Value" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _vm.getUpdateValueChoices(
                                    gisDataField.gis_data_field_id
                                  ).length > 0
                                    ? _c("v-select", {
                                        attrs: {
                                          autocomplete: "off",
                                          label: "Value",
                                          items: _vm.getUpdateValueChoices(
                                            gisDataField.gis_data_field_id
                                          ),
                                          "item-text": "label",
                                          "item-value": "value",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          "hide-details": "auto",
                                        },
                                        on: { input: _vm.onInput },
                                        model: {
                                          value:
                                            _vm.reportDefinition.report
                                              .updateUtiliSyncFields[index]
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reportDefinition.report
                                                .updateUtiliSyncFields[index],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  reportDefinition.report.updateUtiliSyncFields[index].value\n                ",
                                        },
                                      })
                                    : _c("v-text-field", {
                                        attrs: {
                                          label: "Value",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          type: _vm.getFieldType(
                                            gisDataField.gis_data_field_id
                                          ),
                                        },
                                        on: { input: _vm.onInput },
                                        model: {
                                          value:
                                            _vm.reportDefinition.report
                                              .updateUtiliSyncFields[index]
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reportDefinition.report
                                                .updateUtiliSyncFields[index],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  reportDefinition.report.updateUtiliSyncFields[index].value\n                ",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }