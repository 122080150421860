var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("validation-provider", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors, valid }) {
              return [
                _c("v-text-field", {
                  attrs: {
                    label: "Search",
                    "hide-details": "",
                    "error-messages": errors,
                    success: valid,
                    color: "primary",
                    name: "search",
                    "append-icon": _vm.mdiMagnify,
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm.showChildUtiliSyncFields
            ? _c(
                "v-list-item",
                {
                  staticClass: "px-0 mx-0 cursor-pointer",
                  on: {
                    click: function ($event) {
                      _vm.showAddRelatedRecordDialog = true
                    },
                  },
                },
                [
                  _c("v-list-item-content", [
                    _vm._v(" Child UtiliSync Fields "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            {
              staticClass: "px-0 mx-0 cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.insertPlaceholder("site_key")
                },
              },
            },
            [_c("v-list-item-content", [_vm._v(" Site Key ")])],
            1
          ),
          _vm._l(_vm.filteredGisDataFieldChoices, function (g) {
            return _c(
              "v-list-item",
              {
                key: g.value,
                staticClass: "px-0 mx-0 cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.insertPlaceholder(g.label)
                  },
                },
              },
              [
                _c("v-list-item-content", [
                  _vm._v(" " + _vm._s(g.label) + " "),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.showAddRelatedRecordDialog
        ? _c("AddRelatedRecordsDialog", {
            attrs: {
              showAddRelatedRecordDialog: _vm.showAddRelatedRecordDialog,
              mapServiceId: _vm.mapServiceId,
            },
            on: {
              close: function ($event) {
                _vm.showAddRelatedRecordDialog = false
              },
              "added-related-records": _vm.onAddedRelatedRecords,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }