var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showAddRelatedRecordDialog, width: "500px" } },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Add Related Records")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c("section", [_vm._v("Selected Related Layer")]),
                  _c("validation-provider", {
                    attrs: { name: "Layer", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-autocomplete", {
                              attrs: {
                                autocomplete: "off",
                                items: _vm.childMapServiceChoices,
                                label: "Layer",
                                "error-messages": errors,
                                success: valid,
                                "item-text": "label",
                                "item-value": "value",
                              },
                              model: {
                                value: _vm.childMapServiceId,
                                callback: function ($$v) {
                                  _vm.childMapServiceId = $$v
                                },
                                expression: "childMapServiceId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("section", [
                    _vm._v("Selected related records to be added"),
                  ]),
                  _c(
                    "v-simple-table",
                    { attrs: { "fixed-header": "", height: "30vh" } },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticClass: "text-left" }),
                          _c("th", { staticClass: "text-left" }, [
                            _vm._v("UtiliSync Field"),
                          ]),
                          _c("th", { staticClass: "text-left" }),
                        ]),
                      ]),
                      _c(
                        "draggable",
                        {
                          attrs: {
                            list: _vm.childUtiliSyncFields,
                            group: "childUtiliSyncFields",
                            handle: ".section-handle",
                            tag: "tbody",
                          },
                        },
                        _vm._l(
                          _vm.childUtiliSyncFields,
                          function (childUtiliSyncField, index) {
                            return _c(
                              "tr",
                              { key: childUtiliSyncField.gis_data_field_id },
                              [
                                _c(
                                  "td",
                                  { staticClass: "pl-1 pr-0 mx-0 cursor-grab" },
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiDragVertical)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(childUtiliSyncField.name)),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        { attrs: { icon: "" } },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.mdiDotsVertical
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeChildUtiliSyncLayer(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Don't Include "),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end align-center px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.addRelatedRecords },
                },
                [_vm._v(" Add Related Records ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }