<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Reports" />
      <PageLayout>
        <v-data-table
          :headers="headers"
          show-group-by
          group-by="Layer"
          hide-default-footer
          :items="flattenedReportsArray"
          :custom-filter="filter"
          :search="search"
          @click:row="openEditReportDialog"
        >
          <template v-slot:top>
            <div
              class="d-flex justify-space-between align-center gap px-2 py-3"
            >
              <v-text-field
                v-model="search"
                label="Search"
                name="search"
                append-icon="mdi-magnify"
                hide-details="auto"
              />
              <div>
                <v-btn
                  color="primary"
                  @click="
                    showNewReportDialog = true;
                    selectedReportDefinitionId = undefined;
                  "
                  id="add-report-button"
                >
                  + Report
                </v-btn>
              </div>
            </div>

            <section class="px-3 pt-0 pb-2 my-0 d-flex justify-start">
              <v-chip
                class="d-flex"
                @click="showActiveReports = !showActiveReports"
              >
                <div
                  class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
                >
                  {{ showActiveReports ? "Active" : "Archived" }}
                  <v-icon small>
                    {{ mdiSyncCircle }}
                  </v-icon>
                </div>
              </v-chip>
            </section>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list v-if="item.is_active">
                <v-list-item
                  class="cursor-pointer"
                  @click="openEditReportDialog(item)"
                >
                  <v-icon class="mr-1">
                    {{ mdiPencil }}
                  </v-icon>
                  Edit Report
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click="updateIsActive(item.report_definition_id, false)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowDown }}
                  </v-icon>
                  Archive Report
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click.stop="
                    showDeleteDialog = true;
                    itemToDelete = item;
                  "
                >
                  <v-icon class="mr-1">
                    {{ mdiDelete }}
                  </v-icon>
                  Delete Report
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item
                  class="cursor-pointer"
                  @click="updateIsActive(item.report_definition_id, true)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowUp }}
                  </v-icon>
                  Make Active
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-dialog v-model="showNewReportDialog" max-width="400px">
          <NewReportForm
            :mapServicesResults="mapServicesResults"
            @new-report-close="showNewReportDialog = false"
            @create-new-report="onCreateNewReport"
          />
        </v-dialog>

        <v-dialog
          fullscreen
          v-model="showEditDialog"
          :retain-focus="false"
          persistent
          content-class="report-builder-edit-form"
        >
          <ReportBuilderEditForm
            v-if="showEditDialog"
            @report-builder-edit-form-submitted="
              selectedReportDefinitionId = $event
            "
            @report-builder-edit-form-close="onReportBuilderEditFormClose"
            :selectedReportDefinitionId="selectedReportDefinitionId"
            :reportIsNewReport="reportIsNewReport"
          />
        </v-dialog>

        <v-dialog
          v-model="showDeleteDialog"
          max-width="600px"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card class="pt-5">
            <v-card-text>
              Are you sure you want to delete this report? This cannot be
              undone.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="
                  showDeleteDialog = false;
                  itemToDelete = {};
                "
              >
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="deleteItem(itemToDelete)">
                Delete Report
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </PageLayout>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import {
  mdiPencil,
  mdiDelete,
  mdiArchiveArrowUp,
  mdiArchiveArrowDown,
  mdiSyncCircle,
} from "@mdi/js";
import NewReportForm from "@/components/forms/NewReportForm.vue";
import ReportBuilderEditForm from "@/components/forms/ReportBuilderEditForm.vue";
import { mapMutations } from "vuex";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Report",
    align: "start",
    sortable: true,
    groupable: false,
    value: "title",
    width: 200,
  },
  {
    text: "Layer",
    align: "start",
    sortable: true,
    groupable: false,
    value: "serviceName",
  },
  { text: "Actions", value: "actions", sortable: false, groupable: false },
];

export default {
  name: "RepoortsPage",
  components: {
    TopBar,
    PageLayout,
    NewReportForm,
    ReportBuilderEditForm,
  },
  computed: {
    flattenedReportsArray() {
      const reportsArrayFlat = this.reportDefinitions.map((rd) => {
        const {
          report,
          service_name: serviceName,
          parent_map_service_id: parentMapServiceId,
        } = rd;
        let layerName = serviceName;
        if (parentMapServiceId) {
          const parentLayerName = this.getParentLayerName(parentMapServiceId);
          layerName = `${parentLayerName}: ${serviceName}`;
        }

        return {
          title: report?.reportDescription?.title,
          serviceName: layerName,
          Layer: layerName,
          ...rd,
        };
      });
      if (this.showActiveReports) {
        return reportsArrayFlat.filter((l) => l.is_active);
      } else {
        return reportsArrayFlat.filter((l) => !l.is_active);
      }
    },
  },
  data() {
    return {
      headers,
      mapServicesResults: [],
      search: "",
      showActiveReports: true,
      mdiPencil,
      mdiDelete,
      mdiArchiveArrowUp,
      mdiArchiveArrowDown,
      mdiSyncCircle,
      showNewReportDialog: false,
      selectedReportDefinitionId: undefined,
      showEditDialog: false,
      reportDefinitions: [],
      reportIsNewReport: false,
      showDeleteDialog: false,
    };
  },
  mounted() {
    if (!this.$route.query.report) {
      this.downloadMapServices();
      this.downloadReportDefinitions();
    }
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    onCreateNewReport() {
      this.showNewReportDialog = false;
      this.showEditDialog = true;
      this.reportIsNewReport = true;
      this.setMenuState(false);
    },
    onReportBuilderEditFormClose() {
      this.resetURL();
      this.reportIsNewReport = false;
      this.showEditDialog = false;
      this.downloadMapServices();
      this.downloadReportDefinitions();
      this.openMenu();
    },
    async openMenu() {
      this.setMenuState(false);
      await this.$nextTick();
      this.setMenuState(true);
    },
    filter(value, search, item) {
      const { title, serviceName } = item;
      return (
        (title || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase()) ||
        (serviceName || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    resetURL() {
      this.$router.push({
        path: "/reports",
      });
    },
    async downloadMapServices() {
      let authResults;
      try {
        authResults = JSON.parse(localStorage.getItem("auth")) || {};
      } catch (error) {
        authResults = {};
      }

      const { user_group_id: userGroupId } = authResults;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`, {
        params: { userGroupId, isAdmin: true },
      });
      this.mapServicesResults = results;
    },
    async downloadReportDefinitions() {
      let authResults;
      try {
        authResults = JSON.parse(localStorage.getItem("auth")) || {};
      } catch (error) {
        authResults = {};
      }

      const { user_group_id: userGroupId } = authResults;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/report_definitions`, {
        params: { userGroupId, isAdmin: true },
      });
      this.reportDefinitions = results;
    },
    openEditReportDialog(item) {
      this.showEditDialog = true;
      this.selectedReportDefinitionId = item.report_definition_id;
      this.setMenuState(false);

      this.$router.push({
        path: "/reports",
        query: {
          report: item.report_definition_id,
          tab: "report-builder",
        },
      });
    },
    getParentLayerName(mapServiceId) {
      const parentLayerName = this.mapServicesResults.find(
        (parent) => parent.map_service_id === mapServiceId
      )?.service_name;
      return parentLayerName ? parentLayerName : "Undefined";
    },
    async updateIsActive(formDefinitionId, isActiveStatus = true) {
      await axios.put(
        `${APIURL}/report_definitions/${formDefinitionId}/is_active`,
        { is_active: isActiveStatus }
      );
      this.downloadMapServices();
      this.downloadReportDefinitions();
    },
    async deleteItem({ report_definition_id: reportDefintionId }) {
      try {
        await axios.delete(`${APIURL}/report_definitions/${reportDefintionId}`);
      } catch {
        this.showDeleteErrorDialog = true;
      } finally {
        this.itemToDelete = {};
        this.showDeleteDialog = false;
        this.downloadMapServices();
        this.downloadReportDefinitions();
      }
    },
  },
  watch: {
    "$route.query.report": {
      immediate: true,
      deep: true,
      async handler() {
        const reportDefId = this.$route.query.report;
        if (reportDefId) {
          this.showEditDialog = true;
          this.selectedReportDefinitionId = reportDefId;
          this.setMenuState(false);
        }
      },
    },
  },
};
</script>
