<template>
  <v-dialog v-model="showConfirmRemoveContactDialog" width="500">
    <v-card class="m-2">
      <v-card-text>
        <div class="pt-5">
          <h2 class="mb-3">Remove Contact?</h2>
          <p>
            Are you sure you want to remove this person as a contact? Any sites
            where they have been added as a contact will no longer be accessible
            to the contact.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" text color="primary" @click="$emit('cancel')">
          Cancel
        </v-btn>

        <v-btn dark color="primary" @click="$emit('remove-contact')">
          Remove Site Contact
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmRemoveContactDialog",
  props: {
    showConfirmRemoveContactDialog: Boolean,
  },
};
</script>
