var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.showConfirmRemoveContactDialog,
        callback: function ($$v) {
          _vm.showConfirmRemoveContactDialog = $$v
        },
        expression: "showConfirmRemoveContactDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c("v-card-text", [
            _c("div", { staticClass: "pt-5" }, [
              _c("h2", { staticClass: "mb-3" }, [_vm._v("Remove Contact?")]),
              _c("p", [
                _vm._v(
                  " Are you sure you want to remove this person as a contact? Any sites where they have been added as a contact will no longer be accessible to the contact. "
                ),
              ]),
            ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { elevation: "0", text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("remove-contact")
                    },
                  },
                },
                [_vm._v(" Remove Site Contact ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }