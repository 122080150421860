var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      attrs: { fluid: "", height: "100vh", width: "100%", "max-width": "100%" },
    },
    [
      _c(
        "v-toolbar",
        {
          staticClass:
            "white--text elevation-0 flex-grow-0 top-bar mt-n14 mx-0 px-0",
          attrs: { height: "56px" },
        },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mx-0",
                  attrs: { color: "white" },
                  on: {
                    click: function ($event) {
                      _vm.showMenuText = !_vm.showMenuText
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.mdiMenu) + " ")]
              ),
              _c("div", { staticClass: "px-8" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.reportDefinition.report.reportDescription.title
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: {
                dark: "",
                type: "submit",
                outlined: "",
                id: "save-button",
              },
              on: { click: _vm.saveReport },
            },
            [_vm._v(" Save Report ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: { click: _vm.reportBuilderClose },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c("section", { staticClass: "d-flex" }, [
        !_vm.showMenuText
          ? _c(
              "section",
              {
                staticClass: "white overflow-y-hidden",
                style: {
                  height: `calc(100vh - 56px)`,
                  width: `56px`,
                  "min-width": "56px",
                  "max-width": "56px",
                },
                on: {
                  mouseenter: function ($event) {
                    _vm.showMenuText = true
                  },
                },
              },
              [
                _c(
                  "v-list",
                  {
                    ref: "formBuilderMenu",
                    staticClass: "pa-0",
                    attrs: { dense: "" },
                    on: {
                      mouseenter: function ($event) {
                        _vm.showMenuText = true
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.REPORT_BUILDER
                            ? "highlighted"
                            : "",
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.REPORT_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFileDocumentEdit)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.SETTINGS
                            ? "highlighted"
                            : "",
                        attrs: { id: "settingsTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.SETTINGS
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCog)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.showMenuText
          ? _c(
              "section",
              {
                staticClass: "white overflow-y-hidden",
                style: {
                  height: `calc(100vh - 56px)`,
                  width: `56px`,
                  "min-width": "56px",
                  "max-width": "56px",
                },
                on: {
                  mouseleave: function ($event) {
                    _vm.showMenuText = false
                  },
                },
              },
              [
                _c(
                  "v-list",
                  {
                    staticClass: "pa-0 border-right",
                    style: {
                      position: "fixed",
                      top: "56px",
                      "z-index": 1000,
                      height: "calc(100vh - 56px)",
                    },
                    attrs: { dense: "" },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.REPORT_BUILDER
                            ? "highlighted"
                            : "",
                        attrs: { id: "settingsTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.REPORT_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFileDocumentEdit)),
                            ]),
                          ],
                          1
                        ),
                        _vm.showMenuText
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "nav-list-items pl-3" },
                              [_vm._v(" Report Builder ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.SETTINGS
                            ? "highlighted"
                            : "",
                        attrs: { id: "settingsTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.SETTINGS
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCog)),
                            ]),
                          ],
                          1
                        ),
                        _vm.showMenuText
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "nav-list-items pl-3" },
                              [_vm._v(" Settings ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "form",
          {
            staticClass: "elevation-0 pa-0 ma-0 flex-grow-1 d-flex",
            class: { "flex-wrap": _vm.$vuetify.breakpoint.xsOnly },
            style: {
              height: _vm.$vuetify.breakpoint.smAndUp
                ? "calc(100vh - 56px)"
                : "auto",
              "min-width": "0px",
            },
          },
          [
            _c("UnsavedReportChangesDialog", {
              attrs: {
                showReportBuilderEditFormCloseDialog:
                  _vm.showReportBuilderEditFormCloseDialog,
              },
              on: {
                "report-builder-edit-form-save-and-close":
                  _vm.onReportBuilderEditFormSaveAndClose,
                "report-builder-edit-form-close": function ($event) {
                  return _vm.$emit("report-builder-edit-form-close")
                },
                "reverse-changes": function ($event) {
                  _vm.showReportBuilderEditFormCloseDialog = false
                },
              },
            }),
            _c(
              "section",
              {
                staticClass: "flex-grow-1 overflow-y-auto",
                class: {
                  "main-page-background": [
                    _vm.tabs.SETTINGS,
                    _vm.tabs.REPORT_BUILDER,
                  ].includes(_vm.activeTab),
                  "gray-main-page-background": ![
                    _vm.tabs.SETTINGS,
                    _vm.tabs.REPORT_BUILDER,
                  ].includes(_vm.activeTab),
                },
              },
              [
                _vm.activeTab === _vm.tabs.REPORT_BUILDER
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("ReportBuilderTemplateForm", {
                            attrs: { activeTab: _vm.activeTab },
                            on: {
                              input: function ($event) {
                                return _vm.onTemplateChanged()
                              },
                            },
                            model: {
                              value: _vm.reportDefinition,
                              callback: function ($$v) {
                                _vm.reportDefinition = $$v
                              },
                              expression: "reportDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.SETTINGS
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("ReportSettingsForm", {
                            on: {
                              input: function ($event) {
                                return _vm.onTemplateChanged()
                              },
                            },
                            model: {
                              value: _vm.reportDefinition,
                              callback: function ($$v) {
                                _vm.reportDefinition = $$v
                              },
                              expression: "reportDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "1001" },
          attrs: { timeout: 3000 },
          model: {
            value: _vm.showSavedSnackbar,
            callback: function ($$v) {
              _vm.showSavedSnackbar = $$v
            },
            expression: "showSavedSnackbar",
          },
        },
        [_vm._v(" Report Saved ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }