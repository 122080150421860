<template>
  <div
    style="background-color: #f1f2f1"
    class="mx-0 d-flex flex-column align-center"
  >
    <div
      class="d-flex flex-grow-1 border-left border-right"
      :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
      :style="{
        height: $vuetify.breakpoint.xsOnly ? undefined : `calc(100vh - 56px)`,
        width: `100%`,
      }"
    >
      <section
        class="white overflow-y-auto border-right"
        style="height: calc(100vh - 56px); width: 300px"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <v-expansion-panels
          flat
          multiple
          v-model="openPanels"
          class="flex-grow-0"
          style="width: 300px"
          accordion
        >
          <v-expansion-panel :value="0">
            <v-expansion-panel-header>
              <h2>Configuration</h2>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="border-top">
              <ReportConfigurationForm
                v-model="reportDefinition"
                @input="$emit('input', $event)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :value="1" class="border-top border-bottom">
            <v-expansion-panel-header class="header">
              Smart Fields
            </v-expansion-panel-header>

            <v-expansion-panel-content class="border-top">
              <ReportSmartFieldsForm
                :gisDataFields="gisDataFields"
                :mapServiceId="reportDefinition.map_service_id"
                :templatePart="templatePart"
                @insert-placeholder="insertPlaceholder"
                @insert-table-string="insertTableString"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>

      <section
        class="d-flex justify-center pa-0 overflow-hidden flex-grow-1"
        style="height: 100%"
      >
        <validation-provider
          name="Form Template Editor"
          v-slot="{ errors, valid }"
        >
          <ReportTemplateEditor
            :errors="errors"
            :valid="valid"
            :disabled="disabled"
            :reportDefinition="reportDefinition"
            :gisDataFields="gisDataFields"
            :selectedGisDataFieldName="selectedGisDataFieldName"
            :selectedTableHtmlString="selectedTableHtmlString"
            v-model="reportDefinition.html_merge_definition"
            @input="$emit('input', reportDefinition)"
            @input:header="onHeaderInput"
            @input:footer="onFooterInput"
            @input:docSettings="onInputDocSettings"
            @template-part-changed="templatePart = $event"
            ref="templateEditor"
          />
        </validation-provider>
      </section>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { tableCellClassList } from "@/constants/tableCellClassList";
import { mdiPlus, mdiCodeTags, mdiDotsVertical, mdiChevronLeft } from "@mdi/js";
import ReportTemplateEditor from "@/components/shared/ReportTemplateEditor";
import axios from "axios";
import ReportConfigurationForm from "@/components/reports/report-builder-edit-form/report-builder-template-form/ReportConfigurationForm.vue";
import ReportSmartFieldsForm from "@/components/reports/report-builder-edit-form/report-builder-template-form/ReportSmartFieldsForm.vue";
import { TEMPLATE_PART } from "@/constants/templateEditor";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditTemplate",
  props: {
    activeTab: String,
    value: Object,
    disabled: Boolean,
  },
  components: {
    ReportTemplateEditor,
    ReportConfigurationForm,
    ReportSmartFieldsForm,
  },
  data() {
    return {
      showCopiedSnackbar: false,
      showPlaceholderDrawer: false,
      reportDefinition: {},
      tableCellClassList,
      mdiPlus,
      mdiCodeTags,
      mdiDotsVertical,
      mdiChevronLeft,
      showUnsavedChangesDialog: false,
      hasChanges: false,
      openPanels: [0, 1],
      showPageSettingsDialog: false,
      gisDataFields: [],
      selectedGisDataFieldName: undefined,
      selectedTableHtmlString: undefined,
      templatePart: TEMPLATE_PART.BODY,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.reportDefinition = cloneDeep(val);
        if (!this.reportDefinition?.report?.pageSettings) {
          this.reportDefinition.report.pageSettings = {
            pageWidth: 8.5,
            pageHeight: 11,
            marginTop: 1,
            marginBottom: 1,
            marginLeft: 1,
            marginRight: 1,
          };
          this.$emit("input", this.reportDefinition);
        }
        this.getGisDataFields();
      },
    },
    "reportDefinition.html_merge_definition"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.hasChanges = true;
      }
    },
  },
  computed: {
    gisDataFieldChoices() {
      return this.gisDataFields.map((f) => {
        const { gis_data_field_id: value, name: label } = f;
        return { label, value };
      });
    },
  },
  methods: {
    async insertPlaceholder(placeholder) {
      this.selectedGisDataFieldName = placeholder;
      await this.$nextTick();
      this.selectedGisDataFieldName = undefined;
    },
    async insertTableString(tableHtmlString) {
      this.selectedTableHtmlString = tableHtmlString;
      await this.$nextTick();
      this.selectedTableHtmlString = undefined;
    },
    async getGisDataFields() {
      if (!this.reportDefinition?.map_service_id) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: this.reportDefinition.map_service_id,
        },
      });
      this.gisDataFields = results;
    },
    onFormFieldSelected(formFieldId) {
      this.$refs.templateEditor.onFormFieldSelected(formFieldId);
    },
    onInputPageSettings(pageSettings) {
      this.reportDefinition.report.pageSettings = pageSettings;
      this.$emit("input", this.reportDefinition);
    },
    onInputDocSettings(reportDefinition) {
      this.$emit("input", reportDefinition);
    },
    onHeaderInput(headerTemplateHtml) {
      this.reportDefinition.html_merge_definition_header = headerTemplateHtml;
      this.$emit("input", this.reportDefinition);
    },
    onFooterInput(footerTemplateHtml) {
      this.reportDefinition.html_merge_definition_footer = footerTemplateHtml;
      this.$emit("input", this.reportDefinition);
    },
    onCloseButtonClick() {
      if (!this.hasChanges) {
        this.$emit("edit-template-dialog-close");
      } else {
        this.showUnsavedChangesDialog = true;
      }
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.list-background {
  background-color: white;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.header {
  min-height: 0px;
  height: 40px;
}
</style>
