<template>
  <div class="ma-0 pa-0 elevation-0 mx-auto">
    <section class="elevation-0 pa-0">
      <div class="ma-0 pa-0">
        <EditReportTemplate
          :activeTab="activeTab"
          v-model="reportDefinition"
          @input="$emit('input', reportDefinition)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import EditReportTemplate from "@/components/reports/report-builder-edit-form/report-builder-template-form/EditReportTemplate.vue";

export default {
  name: "ReportBuilderTemplateForm",
  props: {
    value: Object,
    activeTab: String,
  },
  components: {
    EditReportTemplate,
  },

  data() {
    return {
      reportDefinition: {},
      showEditTemplateDialog: false,
      showPreviewTemplateDialog: false,
      showPlaceholderDrawer: false,
      tab: 0,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.reportDefinition = cloneDeep(val);
      },
    },
  },
};
</script>

<style scoped>
textarea {
  border: none;
}

textarea:focus {
  outline: none;
}
</style>
