var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Reports" } }),
          _c(
            "PageLayout",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  "show-group-by": "",
                  "group-by": "Layer",
                  "hide-default-footer": "",
                  items: _vm.flattenedReportsArray,
                  "custom-filter": _vm.filter,
                  search: _vm.search,
                },
                on: { "click:row": _vm.openEditReportDialog },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center gap px-2 py-3",
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Search",
                                  name: "search",
                                  "append-icon": "mdi-magnify",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        id: "add-report-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showNewReportDialog = true
                                          _vm.selectedReportDefinitionId =
                                            undefined
                                        },
                                      },
                                    },
                                    [_vm._v(" + Report ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "section",
                            {
                              staticClass:
                                "px-3 pt-0 pb-2 my-0 d-flex justify-start",
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "d-flex",
                                  on: {
                                    click: function ($event) {
                                      _vm.showActiveReports =
                                        !_vm.showActiveReports
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.showActiveReports
                                              ? "Active"
                                              : "Archived"
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiSyncCircle) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              item.is_active
                                ? _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openEditReportDialog(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPencil) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Edit Report "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateIsActive(
                                                item.report_definition_id,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowDown
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Archive Report "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.showDeleteDialog = true
                                              _vm.itemToDelete = item
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Delete Report "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateIsActive(
                                                item.report_definition_id,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowUp
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Make Active "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "400px" },
                  model: {
                    value: _vm.showNewReportDialog,
                    callback: function ($$v) {
                      _vm.showNewReportDialog = $$v
                    },
                    expression: "showNewReportDialog",
                  },
                },
                [
                  _c("NewReportForm", {
                    attrs: { mapServicesResults: _vm.mapServicesResults },
                    on: {
                      "new-report-close": function ($event) {
                        _vm.showNewReportDialog = false
                      },
                      "create-new-report": _vm.onCreateNewReport,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "retain-focus": false,
                    persistent: "",
                    "content-class": "report-builder-edit-form",
                  },
                  model: {
                    value: _vm.showEditDialog,
                    callback: function ($$v) {
                      _vm.showEditDialog = $$v
                    },
                    expression: "showEditDialog",
                  },
                },
                [
                  _vm.showEditDialog
                    ? _c("ReportBuilderEditForm", {
                        attrs: {
                          selectedReportDefinitionId:
                            _vm.selectedReportDefinitionId,
                          reportIsNewReport: _vm.reportIsNewReport,
                        },
                        on: {
                          "report-builder-edit-form-submitted": function (
                            $event
                          ) {
                            _vm.selectedReportDefinitionId = $event
                          },
                          "report-builder-edit-form-close":
                            _vm.onReportBuilderEditFormClose,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showDeleteDialog,
                    callback: function ($$v) {
                      _vm.showDeleteDialog = $$v
                    },
                    expression: "showDeleteDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pt-5" },
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " Are you sure you want to delete this report? This cannot be undone. "
                        ),
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showDeleteDialog = false
                                  _vm.itemToDelete = {}
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(_vm.itemToDelete)
                                },
                              },
                            },
                            [_vm._v(" Delete Report ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }