<template>
  <section>
    <validation-provider v-slot="{ errors, valid }">
      <v-text-field
        v-model="search"
        label="Search"
        hide-details
        :error-messages="errors"
        :success="valid"
        color="primary"
        name="search"
        :append-icon="mdiMagnify"
      />
    </validation-provider>

    <v-list dense>
      <v-list-item
        v-if="showChildUtiliSyncFields"
        class="px-0 mx-0 cursor-pointer"
        @click="showAddRelatedRecordDialog = true"
      >
        <v-list-item-content> Child UtiliSync Fields </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="px-0 mx-0 cursor-pointer"
        @click="insertPlaceholder('site_key')"
      >
        <v-list-item-content> Site Key </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="g of filteredGisDataFieldChoices"
        :key="g.value"
        class="px-0 mx-0 cursor-pointer"
        @click="insertPlaceholder(g.label)"
      >
        <v-list-item-content>
          {{ g.label }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <AddRelatedRecordsDialog
      v-if="showAddRelatedRecordDialog"
      :showAddRelatedRecordDialog="showAddRelatedRecordDialog"
      :mapServiceId="mapServiceId"
      @close="showAddRelatedRecordDialog = false"
      @added-related-records="onAddedRelatedRecords"
    />
  </section>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import AddRelatedRecordsDialog from "@/components/reports/report-builder-edit-form/report-builder-template-form/report-smart-fields-form/AddRelatedRecordsDialog.vue";
import { TEMPLATE_PART } from "@/constants/templateEditor";

export default {
  name: "ReportSmartFieldsForm",
  props: {
    gisDataFields: Array,
    mapServiceId: String,
    templatePart: String,
  },
  components: { AddRelatedRecordsDialog },
  computed: {
    showChildUtiliSyncFields() {
      return this.templatePart === TEMPLATE_PART.BODY;
    },
    gisDataFieldChoices() {
      return this.gisDataFields.map((f) => {
        const { gis_data_field_id: value, name: label } = f;
        return { label, value };
      });
    },
    filteredGisDataFieldChoices() {
      return this.gisDataFieldChoices.filter((c) => {
        if (this.search.length > 0) {
          return c.label.toLowerCase().includes(this.search.toLowerCase());
        }
        return true;
      });
    },
  },
  data() {
    return {
      mdiMagnify,
      search: "",
      showAddRelatedRecordDialog: false,
    };
  },

  methods: {
    insertPlaceholder(placeholder) {
      this.$emit("insert-placeholder", placeholder);
    },
    onAddedRelatedRecords(tableHtmlString) {
      this.showAddRelatedRecordDialog = false;
      this.$emit("insert-table-string", tableHtmlString);
    },
  },
};
</script>
