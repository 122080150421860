<template>
  <v-card class="elevation-0 mx-auto mt-4" max-width="600" id="settingsPanel">
    <v-card-text class="elevation-0">
      <validation-provider
        slim
        v-slot="{ errors, valid }"
        name="Title"
        rules="required"
      >
        <v-text-field
          v-model="reportDefinition.report.reportDescription.title"
          label="Title"
          hide-details="auto"
          :error-messages="errors"
          :success="valid"
          color="primary"
          @input="$emit('input', reportDefinition)"
        />
      </validation-provider>

      <section class="py-3">Update UtiliSync Fields</section>

      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">UtiliSync Field</th>
            <th class="text-left">Value</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(gisDataField, index) of gisDataFields"
            :key="gisDataField.gis_data_field_id"
          >
            <td class="py-1">{{ gisDataField.name }}</td>
            <td class="py-1">
              <validation-provider slim v-slot="{ errors, valid }" name="Value">
                <v-select
                  v-if="
                    getUpdateValueChoices(gisDataField.gis_data_field_id)
                      .length > 0
                  "
                  autocomplete="off"
                  v-model="
                    reportDefinition.report.updateUtiliSyncFields[index].value
                  "
                  label="Value"
                  :items="getUpdateValueChoices(gisDataField.gis_data_field_id)"
                  item-text="label"
                  item-value="value"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  hide-details="auto"
                  @input="onInput"
                ></v-select>
                <v-text-field
                  v-else
                  v-model="
                    reportDefinition.report.updateUtiliSyncFields[index].value
                  "
                  label="Value"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  :type="getFieldType(gisDataField.gis_data_field_id)"
                  @input="onInput"
                />
              </validation-provider>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import FIELD_TYPES from "@/constants/gisDataFields";

const APIURL = process.env.VUE_APP_API_URL;
const VALUE_CHOICES = {
  NONE: null,
  CURRENT_DATE: "current-date",
};

export default {
  name: "ReportSettingsForm",
  props: {
    value: Object,
  },
  data() {
    return {
      reportDefinition: {},
      gisDataFields: [],
      FIELD_TYPES,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.reportDefinition = { ...val };
        if (
          !Array.isArray(this.reportDefinition?.report?.updateUtiliSyncFields)
        ) {
          this.reportDefinition.report.updateUtiliSyncFields = [];
        }
      },
    },
    "reportDefinition.report_definition_id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getGisdataFields();
        }
      },
    },
    gisDataFields: {
      deep: true,
      immediate: true,
      handler(val) {
        const updateUtiliSyncFields = val.map((v) => {
          const { gis_data_field_id: gisDataFieldId } = v;
          let updateUtiliSyncFieldsEntry;
          const updateUtiliSyncFields =
            this.reportDefinition?.report?.updateUtiliSyncFields;
          if (Array.isArray(updateUtiliSyncFields)) {
            updateUtiliSyncFieldsEntry = updateUtiliSyncFields.find((f) => {
              return f.gis_data_field_id === gisDataFieldId;
            });
          }
          return {
            gis_data_field_id: gisDataFieldId,
            value: updateUtiliSyncFieldsEntry?.value ?? VALUE_CHOICES.NONE,
          };
        });
        this.reportDefinition.report.updateUtiliSyncFields =
          updateUtiliSyncFields;
      },
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.reportDefinition);
    },
    async getGisdataFields() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: { map_service_id: this.reportDefinition.map_service_id },
      });
      this.gisDataFields = results;
    },
    getUpdateValueChoices(gisDataFieldId) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
      if (
        gisDataField?.type === FIELD_TYPES.STRING &&
        gisDataField?.gis_data_field_options?.length > 0
      ) {
        const gisDataFieldOptionChoices =
          gisDataField.gis_data_field_options.map((o) => {
            const { label, value } = o;
            return { label, value };
          });
        return [
          { value: VALUE_CHOICES.NONE, label: "None" },
          ...gisDataFieldOptionChoices,
        ];
      } else if (gisDataField?.type === FIELD_TYPES.DATE) {
        return [
          { value: VALUE_CHOICES.NONE, label: "None" },
          { value: VALUE_CHOICES.CURRENT_DATE, label: "Current Date" },
        ];
      }
      return [];
    },
    getFieldType(gisDataFieldId) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
      if (gisDataField?.type === FIELD_TYPES.NUMBER) {
        return "number";
      }
      return "text";
    },
  },
};
</script>
