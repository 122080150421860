<template>
  <section class="d-flex flex-column justify-center align-center my-0 py-3">
    <v-btn
      text
      color="primary"
      @click="showReportPageSettingsDialog = true"
      v-if="showPageSettings"
      :disabled="disabled"
    >
      Page Settings
    </v-btn>

    <ReportPageSettingsDialog
      :showReportPageSettingsDialog="showReportPageSettingsDialog"
      v-if="showReportPageSettingsDialog"
      :value="value"
      :disabled="disabled"
      @close-doc-settings-dialog="showReportPageSettingsDialog = false"
      @input="onInputDocSettings"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import { mdiPlus, mdiDotsVertical } from "@mdi/js";
import ReportPageSettingsDialog from "@/components/reports/report-builder-edit-form/report-builder-template-form/report-configuration-form/ReportPageSettingsDialog.vue";

export default {
  name: "ReportConfigurationForm",
  props: {
    value: Object,
    disabled: Boolean,
    showPageSettings: { type: Boolean, default: true },
  },
  components: { ReportPageSettingsDialog },
  data() {
    return {
      reportDefinition: {},
      mdiPlus,
      mdiDotsVertical,
      showPreviewTemplateDialog: false,
      showReportPageSettingsDialog: false,
    };
  },
  beforeMount() {
    this.reportDefinition = cloneDeep(this.value);
  },
  methods: {
    onInputDocSettings(reportDefinition) {
      this.$emit("input", reportDefinition);
      this.showReportPageSettingsDialog = false;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.reportDefinition = cloneDeep(val);
      },
    },
  },
};
</script>
